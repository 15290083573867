// @flow
import moment from 'moment';
import * as change_case from 'change-case';
import type {
  TOctoResourceError,
  TOctoAccountACH,
  TOctoAccountLiability,
} from './types';
import {
  OctoAccountTypes,
  OctoAccountStatuses,
} from '../enums/OctoAccount';

class OctoAccount {
  id: string;
  holder_id: string;
  status: string;
  type: string;
  ach: ?TOctoAccountACH;
  liability: ?TOctoAccountLiability;
  clearing: ?any;
  capabilities: Array<string>;
  error: ?TOctoResourceError;
  metadata: ?any;
  created_at: string;
  updated_at: string;

  constructor(opts: any) {
    this.id = opts.id;
    this.holder_id = opts.holder_id;
    this.status = opts.status;
    this.type = opts.type;
    this.ach = opts.ach;
    this.liability = opts.liability;
    this.clearing = opts.clearing;
    this.capabilities = opts.capabilities;
    this.error = opts.error;
    this.metadata = opts.metadata;
    this.created_at = opts.created_at;
    this.updated_at = opts.updated_at;
  }

  static init_from_raw(opts: any): OctoAccount {
    return new OctoAccount(opts);
  }

  get_presentable_created_at(format: string = 'MM/DD/YY, hh:mm A'): string {
    return this.created_at ? moment(this.created_at).format(format) : 'None';
  }

  get_presentable_mask(): string {
    switch (this.type) {
      case OctoAccountTypes.ach: return this.ach?.number ? this.ach.number.substr(-4) : 'None';
      case OctoAccountTypes.liability: return this.liability?.mask || '';
      case OctoAccountTypes.clearing: return this.clearing?.number ? this.clearing.number.substr(-4) : 'None';
      default: return 'None';
    }
  }

  get_presentable_mch_id(): string {
    return this.liability?.mch_id || '';
  }

  get_presentable_type(): string {
    switch (this.type) {
      case OctoAccountTypes.ach: return this.ach?.type ? `ACH (${change_case.capitalCase(this.ach.type)})` : 'None';
      case OctoAccountTypes.clearing: return this.clearing?.type ? `Clearing (${change_case.capitalCase(this.clearing.type)})` : 'None';
      case OctoAccountTypes.liability: return this.type ? change_case.capitalCase(this.type) : 'None';
      default: return 'None';
    }
  }

  get_presentable_liability_type(): string {
    switch (this.type) {
      case OctoAccountTypes.liability: return this.liability?.type ? change_case.capitalCase(this.liability.type) : 'None';
      default: return 'None';
    }
  }

  get_presentable_ach_type(): string {
    switch (this.type) {
      case OctoAccountTypes.ach: return this.ach?.type ? change_case.capitalCase(this.ach.type) : 'None';
      default: return 'None';
    }
  }

  get_routing_number(): string {
    switch (this.type) {
      case OctoAccountTypes.ach: return this.ach?.routing || '';
      case OctoAccountTypes.clearing: return this.clearing?.routing || '';
      default: return 'None';
    }
  }

  get_account_number(): string {
    switch (this.type) {
      case OctoAccountTypes.ach: return this.ach?.number || '';
      case OctoAccountTypes.clearing: return this.clearing?.number || '';
      default: return 'None';
    }
  }

  get_presentable_type_with_mask(): string {
    return [
      this.get_presentable_type(),
      this.get_presentable_mask(),
    ].filter(Boolean).join(': ');
  }

  get_presentable_status(): string {
    return this.status ? change_case.capitalCase(this.status) : 'None';
  }

  get_color_for_status(): string {
    switch (this.status) {
      case OctoAccountStatuses.active: return '#00ECAC';
      case OctoAccountStatuses.processing: return '#FCF7BC';
      case OctoAccountStatuses.closed: return 'default';
      case OctoAccountStatuses.disabled: return 'danger';
      default: return 'default';
    }
  }

  get_liability_data(): ?any {
    return (this.liability && this.liability[this.liability.type])
      ? this.liability[this.liability.type]
      : null;
  }
}

export default OctoAccount;
