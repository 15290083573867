// @flow
import * as React from 'react';
import type { Node } from 'react';
import { EuiSelectable, EuiSpacer, EuiText } from '@elastic/eui';
import * as Base from '../../Base';
import type { TSelectACHModalProps } from '../types';
import { useAccounts } from '../../../hooks';

function SelectACHModal(props: TSelectACHModalProps): Node {
  const {
    account,
    isOpen,
    onClose,
    onSelect,
  } = props;

  // Select account
  const [filter] = React.useState({ type: 'ach', status: 'active' });

  const accounts = useAccounts(filter);
  const accounts_list = (accounts.data || [])
    .filter((acc) => acc.account.id !== account.account.id && acc.can_send_payments());

  return (
    <Base.Modal
      title="Select an ACH account"
      isOpen={isOpen}
      onClose={onClose}>
      <Base.Container minHeight={300}>
        <EuiSelectable
          searchable
          singleSelection
          searchProps={{ placeholder: 'Search ACH accounts' }}
          options={accounts_list.map((item) => ({
            label: item.holder.get_presentable_name(),
            searchableLabel: [
              item.holder.get_presentable_name(),
              item.get_presentable_name(),
              item.account.id,
            ].join('\n'),
            account: item,
          }))}
          renderOption={(item) => (
            <Base.Container onClick={() => onSelect(item.account)}>
              <EuiText size="xs">
                <strong>{item.account.holder.get_presentable_name()}</strong>
                <br />
                <small>{item.account.get_presentable_name()}</small>
              </EuiText>
            </Base.Container>
          )}
          listProps={{ rowHeight: 70, showIcons: false }}>
          {(list, search) => (
            <Base.Container>
              {search}
              <EuiSpacer />
              {list}
            </Base.Container>
          )}
        </EuiSelectable>
      </Base.Container>
    </Base.Modal>
  );
}

export default SelectACHModal;
