// @flow
import React from 'react';
import type { Node } from 'react';
import ContainerSplit from './ContainerSplit';
import FormField from './FormField';
import type { TFormFieldSplitProps } from './types';

function FormFieldSplit(props: TFormFieldSplitProps): Node {
  const { left, right } = props;

  return (
    <ContainerSplit
      equal
      left={<FormField fullWidth {...left} />}
      right={<FormField fullWidth {...right} />} />
  );
}

export default FormFieldSplit;
