// @flow
import React from 'react';
import type { Node } from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
} from '@elastic/eui';
import type { TContainerSplitProps } from './types';

function ContainerSplit(props: TContainerSplitProps): Node {
  const {
    equal = false,
    leftGrow,
    rightGrow,
    left,
    right,
    justify = 'center',
    align = 'center',
    gutterSize = 'm',
  } = props;

  return (
    <EuiFormRow fullWidth>
      <EuiFlexGroup responsive gutterSize={gutterSize} alignItems={align} justifyContent={justify}>
        <EuiFlexItem grow={equal || leftGrow}>{left}</EuiFlexItem>
        <EuiFlexItem grow={equal || rightGrow}>{right}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiFormRow>
  );
}

export default ContainerSplit;
