// @flow
import * as React from 'react';
import { EuiBadge, EuiDescriptionList, EuiText } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelOverview(props: TPanelDefaultProps): Node {
  const { merchant } = props;
  if (!merchant) return null;

  return (
    <Base.AccordionPanelContainer title="Overview">
      <Base.ContainerSplit
        equal
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Categories</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>
                      {merchant.types.length > 0
                        ? merchant.types.map((type) => (
                          <EuiBadge key={type}><code>{type}</code></EuiBadge>
                        ))
                        : 'None'}
                    </h3>
                  </EuiText>
                ),
              },
            ]} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Merchant ID</span></EuiText>,
                description: <EuiText size="xs"><h3>{merchant.mch_id}</h3></EuiText>,
              },
            ]} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelOverview;
