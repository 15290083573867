// @flow
import * as React from 'react';
import { EuiImage } from '@elastic/eui';
import type { Node } from 'react';
import type { TMethodLogoProps } from './types';

const logos = {
  light: 'https://static.methodfi.com/brand/logo-horizontal-vibrantgreen-white.png',
  dark: 'https://static.methodfi.com/brand/logo-horizontal-vibrantgreen-darkgreen.png',
};

function MethodLogo(props: TMethodLogoProps): Node {
  const { light } = props;
  return (
    <EuiImage
      size="s"
      alt="MethodFi Logo"
      src={light ? logos.light : logos.dark} />
  );
}

export default MethodLogo;
