// @flow
import React from 'react';
import type { Node } from 'react';
import { useHistory } from 'react-router-dom';
import { EuiIcon, EuiSpacer, EuiText } from '@elastic/eui';
import type { TModalEnvironmentsProps } from './types';
import * as Base from '../Base';
import * as hooks from '../../hooks';
import { colors, sizes } from '../../styles';
import Team from '../../lib/Team';
import { TeamEnvs } from '../../enums/Team';

function ModalEnvironments(props: TModalEnvironmentsProps): Node {
  const { isOpen, onClose } = props;
  if (!isOpen) return null;

  const history = useHistory();
  const user = hooks.useUser();
  const activeEnv = Team.getActiveEnv();

  const environments = [
    {
      label: 'Development',
      key: TeamEnvs.development,
      available: Boolean(user.getActiveTeam().getDevelopmentKey()),
      active: activeEnv === TeamEnvs.development,
    },
    {
      label: 'Sandbox',
      key: TeamEnvs.sandbox,
      available: Boolean(user.getActiveTeam().getSandboxKey()),
      active: activeEnv === TeamEnvs.sandbox,
    },
    {
      label: 'Production',
      key: TeamEnvs.production,
      available: Boolean(user.getActiveTeam().getProductionKey()),
      active: activeEnv === TeamEnvs.production,
    },
  ];

  return (
    <Base.Modal
      title="Select Environment"
      isOpen={isOpen}
      onClose={onClose}>
      {environments.map((environment) => [
        <Base.Container
          flex
          key={environment.key}
          pa={sizes.s}
          ba={1}
          bac={colors.gray}
          radius={sizes.xxs}
          align="center"
          justify="space-between"
          onClick={() => {
            if (environment.active) onClose();
            else if (!environment.available) {
              history.push('/keys');
              onClose();
            } else {
              Team.setActiveEnv(environment.key);
              window.location.reload();
            }
          }}>
          <EuiText size="xs"><strong>{environment.label}</strong></EuiText>
          {environment.active
            ? <EuiIcon type="checkInCircleFilled" color={colors.green} />
            : <EuiIcon type="arrowRight" />}
        </Base.Container>,
        <EuiSpacer key={`${environment.key}-spacer`} size="s" />,
      ])}
    </Base.Modal>
  );
}

export default ModalEnvironments;
