// @flow
export const UserPurposes = {
  PersonalFinance: 'PersonalFinance',
  ConsumerPayments: 'ConsumerPayments',
  Lending: 'Lending',
  BankingAndBrokerage: 'BankingAndBrokerage',
  BusinessFinancialManagement: 'BusinessFinancialManagement',
  Other: 'Other',
};

export const UserRoles = {
  Developer: 'Developer',
  CustomerSupport: 'CustomerSupport',
  ProductManager: 'ProductManager',
  Other: 'Other',
};
