// @flow
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import {
  EuiFieldText,
  EuiFormRow,
  EuiFormControlLayout,
  EuiSelect,
  EuiFieldSearch,
  EuiFieldNumber,
} from '@elastic/eui';
import type { TFormFieldProps, TFormFieldTypes } from './types';
import { FormFieldTypes } from '../../enums';

function getFieldComponent(type: TFormFieldTypes): any {
  switch (type) {
    case FormFieldTypes.Email:
    case FormFieldTypes.Text: return EuiFieldText;
    case FormFieldTypes.Select: return EuiSelect;
    case FormFieldTypes.Search: return EuiFieldSearch;
    case FormFieldTypes.Number: return EuiFieldText;
    default: return EuiFieldText;
  }
}

function FormField(props: TFormFieldProps): Node {
  const {
    name,
    label,
    placeholder,
    isInvalid,
    isLoading,
    error,
    onChange,
    type,
    isDisabled,
    fullWidth,
    options = [],
    maxLength,
    minLength,
    prepend,
    append,
    readOnly,
    onClick,
    optional,
    controlledValue,
  } = props;
  const [_value, _setValue] = useState('');
  const FieldComponent = getFieldComponent(type);

  const _onChange = (event) => {
    _setValue(event.target.value);
    if (onChange) onChange(event.target.value);
  };

  const effectInputs = [];
  if (controlledValue) effectInputs.push(props.value);

  useEffect(() => {
    if (props.value) {
      _setValue(props.value);
      if (onChange) onChange(props.value);
    }
  }, effectInputs);

  const field_opts = {
    name,
    fullWidth,
    placeholder,
    options,
    value: _value,
    disabled: isDisabled || isLoading,
    onChange: _onChange,
    maxLength: maxLength || null,
    minLength: minLength || null,
    prepend,
    append,
    readOnly,
    onClick,
  };

  switch (type) {
    case FormFieldTypes.Search: {
      field_opts.isClearable = true;
      break;
    }
    case FormFieldTypes.Number: {
      field_opts.onChange = (event) => {
        if (event.target.value.split('.')[1]?.length > 2) return;
        if (/\D+/ig.test(event.target.value.replace(/\./, ''))) return;
        _onChange(event);
      };
      break;
    }
    default:
  }

  const _isInvalid = Boolean(error) || isInvalid;
  return (
    <EuiFormRow
      label={!optional ? `${label}*` : label}
      error={error}
      fullWidth={fullWidth}
      isInvalid={(!optional || _value) && _isInvalid}>
      <EuiFormControlLayout isLoading={isLoading} fullWidth={fullWidth}>
        <FieldComponent {...field_opts} />
      </EuiFormControlLayout>
    </EuiFormRow>
  );
}

export default FormField;
