// @flow
import React from 'react';
import { EuiLoadingSpinner } from '@elastic/eui';
import type { Node } from 'react';
import type { TLoaderFillProps } from './types';

function LoaderFill(props: TLoaderFillProps): Node {
  return (
    <div style={{
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      zIndex: 100,
    }}>
      <EuiLoadingSpinner size="xl" />
    </div>
  );
}

export default LoaderFill;
