// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiText,
  EuiSpacer,
} from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelStepProps } from '../types';

function StepCreateEntity(props: TPanelStepProps): Node {
  return (
    <Base.Container>
      {/* Title + description */}
      <EuiText>
        <h3>Register your user with Method</h3>
        <p>
          An entity is a representation of your application&apos;s
          end-user. Create one using this request.
        </p>
      </EuiText>

      <EuiSpacer />

      <Base.CodeBlock>
        {`curl https://dev.methodfi.com/entities \\
    -X POST \\
    -H "Authorization: Bearer <DEV_API_KEY>" \\
    -H "Content-Type: application/json" \\
    -d '{
      "type": "individual",
      "individual": {
        "first_name": "Kevin",
        "last_name": "Doyle",
        "phone": "+16505555555",
        "dob": "2011-09-09"
      }
    }'`}
      </Base.CodeBlock>
    </Base.Container>
  );
}

export default StepCreateEntity;
