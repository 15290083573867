// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import { useHistory } from 'react-router-dom';
import { EuiListGroup } from '@elastic/eui';
import * as Base from '../Base';
import type { TPanelNavigationProps } from './types';
import { colors, sizes } from '../../styles';
import User from '../../lib/User';
import ModalSupport from './ModalSupport';
import * as hooks from '../../hooks';
import ModalEnvironments from './ModalEnvironments';

function PanelNavigation(props: TPanelNavigationProps): Node {
  const user = hooks.useUser();
  const team = user.getActiveTeam();
  const productionKey = team.getProductionKey();
  const history = useHistory();

  // Modal states
  const [isModalSupportOpen, setIsModalSupportOpen] = useState(false);
  const [isModalEnvironmentsOpen, setIsModalEnvironmentsOpen] = useState(false);

  const mainListGroups = [
    {
      label: null,
      items: [
        { label: 'Get Started', iconType: 'apps', path: '/' },
        { label: 'Entities', iconType: 'users', path: '/entities' },
        { label: 'Accounts', iconType: 'inspect', path: '/accounts' },
        { label: 'Payments', iconType: 'merge', path: '/payments' },
        { label: 'Merchants', iconType: 'globe', path: '/merchants' },
        // TODO[mdelcarmen]: webhooks
        // { label: 'Webhooks', iconType: 'bolt', path: '/webhooks' },
      ],
    },
    {
      label: 'Team',
      items: [
        { label: 'Keys', iconType: 'eql', path: '/keys' },
        { label: 'Settings', iconType: 'controlsVertical', path: '/settings' },
        !productionKey && {
          label: 'Apply for Production',
          iconType: 'beaker',
          onClick: () => user.onApplyForProduction(),
        },
      ].filter(Boolean),
    },
    {
      label: 'Resources',
      items: [
        {
          label: 'API Documentation',
          iconType: 'apmTrace',
          onClick: () => window.open('https://docs.methodfi.com', '_blank'),
        },
        { label: 'Support', iconType: 'help', onClick: () => setIsModalSupportOpen(true) },
      ],
    },
  ];

  const footerListGroups = [
    {
      items: [
        { label: 'Select Environment', iconType: 'node', onClick: () => setIsModalEnvironmentsOpen(true) },
        { label: 'Logout', iconType: 'exit', onClick: User.logoutAsync },
      ],
    },
  ];

  const renderListGroups = (listGroups) => {
    const createListLabel = (label) => ({ label: <strong>{label}</strong> });
    return listGroups.map((listGroup) => {
      const data = listGroup.label ? [createListLabel(listGroup.label), ...listGroup.items] : listGroup.items;
      // $FlowFixMe
      const listItems = data.map(({ path, onClick, ...rest }) => ({
        ...rest,
        onClick: onClick || (path && (() => history.push(path))),
        isActive: path === history.location.pathname,
        style: { padding: sizes.s, color: colors.white },
      }));

      return <EuiListGroup key={Math.random().toString()} listItems={listItems} style={{ paddingTop: 0 }} />;
    });
  };

  return (
    <Base.Container fullHeight flex="column" justify="space-between">
      <Base.Container fullWidth mt={sizes.l} pb={sizes.l} pl={sizes.l} bb={1} bbc={colors.black}>
        <Base.MethodLogo light />
      </Base.Container>

      {/* Logo */}
      <Base.Container scroll height="calc(100vh - 60px)" flex="column" justify="space-between">
        {/* Content */}
        <Base.Container px={sizes.s} mt={sizes.l}>
          {renderListGroups(mainListGroups)}
        </Base.Container>

        {/* Logout */}
        <Base.Container fullWidth px={sizes.s} mb={sizes.m} mt={sizes.xl * 2} align="flexEnd">
          {renderListGroups(footerListGroups)}
        </Base.Container>
      </Base.Container>

      {/* Support Modals */}
      <ModalSupport
        isOpen={isModalSupportOpen}
        onClose={() => setIsModalSupportOpen(false)} />
      <ModalEnvironments
        isOpen={isModalEnvironmentsOpen}
        onClose={() => setIsModalEnvironmentsOpen(false)} />

    </Base.Container>
  );
}

export default PanelNavigation;
