// @flow
import * as React from 'react';
import type { Node } from 'react';
import { EuiText, EuiSpacer } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelStepProps } from '../types';
import * as hooks from '../../../hooks';

function StepGetKeys(props: TPanelStepProps): Node {
  const user = hooks.useUser();
  const team = user.getActiveTeam();
  const developmentKey = team.getDevelopmentKey();

  return (
    <Base.Container>
      {/* Title + description */}
      <EuiText>
        <h3>API Keys</h3>
        <p>
          This is the secret key for your development environment - be sure to keep it safe.
        </p>
      </EuiText>

      <EuiSpacer />

      <Base.CopyText label="Development" sensitive value={developmentKey} />

    </Base.Container>
  );
}

export default StepGetKeys;
