// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import * as Layouts from '../components/Layouts';
import * as TeamSettings from '../components/TeamSettings';

function TeamSettingsPage(): Node {
  return (
    <Layouts.Page withSidebar title="Settings">
      <TeamSettings.PanelTeam />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(TeamSettingsPage, { name: 'TeamSettingsPage' });
