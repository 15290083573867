// @flow
import moment from 'moment';
import * as change_case from 'change-case';
import type {
  TOctoResourceError,
  TOctoEntityIndividual,
  TOctoEntityCorporation,
  TOctoEntityReceiveOnly,
  TOctoEntityAddress,
} from './types';
import { OctoEntityStatuses } from '../enums/OctoEntity';

class OctoEntity {
  id: string;
  type: string;
  individual: ?TOctoEntityIndividual;
  corporation: ?TOctoEntityCorporation;
  receive_only: ?TOctoEntityReceiveOnly;
  capabilities: Array<string>;
  available_capabilities: Array<string>;
  pending_capabilities: Array<string>;
  error: ?TOctoResourceError;
  address: TOctoEntityAddress;
  status: string;
  metadata: ?any;
  created_at: string;
  updated_at: string;

  constructor(opts: any) {
    this.id = opts.id;
    this.type = opts.type;
    this.individual = opts.individual;
    this.corporation = opts.corporation;
    this.receive_only = opts.receive_only;
    this.capabilities = opts.capabilities;
    this.available_capabilities = opts.available_capabilities;
    this.pending_capabilities = opts.pending_capabilities;
    this.error = opts.error;
    this.address = opts.address;
    this.status = opts.status;
    this.metadata = opts.metadata;
    this.created_at = opts.created_at;
    this.updated_at = opts.updated_at;
  }

  static init_from_raw(raw_opts: any): OctoEntity {
    return new OctoEntity(raw_opts);
  }

  get_presentable_created_at(format: string = 'MM/DD/YY, hh:mm A'): string {
    return this.created_at && moment(this.created_at).format(format);
  }

  get_logo(): string {
    return this.individual
      ? 'https://static.methodfi.com/assets/entity-individual-icon.png'
      : 'https://static.methodfi.com/assets/entity-corporation-icon.png';
  }

  get_presentable_name(): string {
    let name;
    if (this.individual) {
      name = [this.individual.first_name, this.individual.last_name]
        .filter(Boolean).join(' ');
    } else if (this.corporation) {
      name = this.corporation.name;
    }

    return name ? change_case.capitalCase(name) : 'Unnamed Entity';
  }

  get_presentable_type(): string {
    return this.type ? change_case.capitalCase(this.type) : 'None';
  }

  get_presentable_status(): string {
    return this.status ? change_case.capitalCase(this.status) : 'None';
  }

  get_color_for_status(): string {
    switch (this.status) {
      case OctoEntityStatuses.active: return '#00ECAC';
      case OctoEntityStatuses.incomplete: return 'default';
      case OctoEntityStatuses.disabled: return 'danger';
      default: return 'default';
    }
  }

  get_presentable_individual_first_name(): string {
    if (this.individual && this.individual.first_name) return this.individual.first_name;
    return 'None';
  }

  get_presentable_individual_last_name(): string {
    if (this.individual && this.individual.last_name) return this.individual.last_name;
    return 'None';
  }

  get_presentable_individual_phone(): string {
    if (this.individual && this.individual.phone) return this.individual.phone;
    return 'None';
  }

  get_presentable_individual_email(): string {
    if (this.individual && this.individual.email) return this.individual.email;
    return 'None';
  }

  get_presentable_individual_dob(format: string = 'MMMM Do YYYY'): string {
    if (this.individual && this.individual.dob) return moment(this.individual.dob, 'YYYY-MM-DD').format(format);
    return 'None';
  }

  get_presentable_address(): string {
    if (this.address) {
      const address_parts = [
        this.address.line1,
        this.address.line2,
        this.address.city,
        this.address.state,
        this.address.zip,
      ].filter(Boolean);
      if (address_parts.length !== 0) return address_parts.join(' ');
    }

    return 'None';
  }

  get_presentable_corp_dba(): string {
    if (this.corporation && this.corporation.dba) return this.corporation.dba;
    return 'None';
  }

  get_presentable_corp_ein(): string {
    if (this.corporation && this.corporation.ein) return this.corporation.ein;
    return 'None';
  }

  get_presentable_owners(): Array<any> {
    if (this.corporation && this.corporation.owners) {
      return this.corporation.owners.map((owner) => {
        const formatted_owner = {
          name: [owner.first_name, owner.last_name].filter(Boolean).join(' ') || null,
          phone: owner.phone || null,
          email: owner.email || null,
          dob: owner.dob ? moment(owner.dob, 'YYYY-MM-DD').format('MMMM Do YYYY') : null,
          address: null,
        };

        if (owner.address) {
          const address_parts = [
            owner.address.line1,
            owner.address.line2,
            owner.address.city,
            owner.address.state,
            owner.address.zip,
          ].filter(Boolean);
          if (address_parts.length !== 0) formatted_owner.address = address_parts.join(' ');
        }

        return formatted_owner;
      });
    }

    return [];
  }
}

export default OctoEntity;
