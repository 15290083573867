// @flow
export type TLoginPageProps = {
  isNewUser: boolean,
};

export const Pages = {
  Login: {
    path: '/login',
    crumbName: null,
  },
  LoginCreateTeam: {
    path: '/login/create_team',
    crumbName: null,
  },
  Dashboard: {
    path: '/',
    crumbName: 'Get Started',
  },
  Payments: {
    path: '/payments',
    crumbName: 'Payments',
  },
  PaymentDetails: {
    path: '/payments/:paymentId',
    crumbName: 'Payment Details',
  },
  Accounts: {
    path: '/accounts',
    crumbName: 'Accounts',
  },
  AccountDetails: {
    path: '/accounts/:accountId',
    crumbName: 'Account Details',
  },
  Entities: {
    path: '/entities',
    crumbName: 'Entities',
  },
  EntityDetails: {
    path: '/entities/:entityId',
    crumbName: 'Entity Details',
  },
  Merchants: {
    path: '/merchants',
    crumbName: 'Merchants',
  },
  MerchantDetails: {
    path: '/merchants/:mchId',
    crumbName: 'Merchant Details',
  },
  // TODO[mdelcarmen]: webhooks
  // Webhooks: {
  //   path: '/webhooks',
  //   crumbName: 'Webhooks',
  // },
  // WebhookDetails: {
  //   path: '/webhooks/:whkId',
  //   crumbName: 'Webhook Details',
  // },
  Keys: {
    path: '/keys',
    crumbName: 'API Keys',
  },
  Settings: {
    path: '/settings',
    crumbName: 'Settings',
  },
};
