// @flow
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AtomClient from '../lib/AtomClient';
import OctoPopulatedPayment from '../lib/OctoPopulatedPayment';

export default function usePayment(id: string): { completed: boolean, data: null } {
  const [__, raiseError] = useState(null); // eslint-disable-line
  const [payment, setPayment] = useState({ completed: false });
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setPayment({
          completed: true,
          data: await AtomClient.getResource({
            id,
            resource_name: 'payments',
            resource_init: OctoPopulatedPayment.init_from_raw,
          }),
        });
      } catch (error) {
        history.push('/payments');
        raiseError(() => { throw error; });
      }
    })();
  }, []);

  return payment;
}
