// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiButton,
  EuiContextMenu,
  EuiPopover,
} from '@elastic/eui';
import { useMethod } from 'react-method-elements';
import { useHistory } from 'react-router-dom';
import type { TRightContentProps } from '../types';
import AtomClient from '../../../lib/AtomClient';
import Team from '../../../lib/Team';
import CreateACHAccountModal from '../CreateACHAccountModal';
import OctoAccount from '../../../lib/OctoAccount';

function RightContent(props: TRightContentProps): Node {
  const history = useHistory();
  const { entity } = props;
  if (!entity) return null;

  const [isOpen, setIsOpen] = React.useState(false);
  const [isCreateACHOpen, setIsCreateACHOpen] = React.useState(false);

  const method = useMethod({
    env: Team.getActiveEnv() === 'development' ? 'dev' : Team.getActiveEnv(), // (dev / sandbox / production),
    onSuccess: async ({ element_type, public_account_token }) => {
      switch (element_type) {
        case 'link': {
          const octo_account = await AtomClient.createResource<OctoAccount>({
            data: { public_account_token },
            resource_name: 'elements/accounts/exchange',
            resource_init: OctoAccount.init_from_raw,
          });

          history.push(`/accounts/${octo_account.id}`);
          break;
        }
        default:
      }
    },
    onExit: ({ element_type }) => {
      switch (element_type) {
        case 'auth': window.location.reload(); break;
        case 'link': break;
        default:
      }
    },
  });

  const canInitAuthSession = [
    entity.entity.available_capabilities.includes('data:retrieve'),
    entity.entity.pending_capabilities.includes('data:retrieve'),
  ].some(Boolean);
  const onCreateAuthSession = async () => {
    const element_token = await AtomClient.createResource<string>({
      data: { entity_id: entity.entity.id, type: 'auth', auth: {} },
      resource_name: 'elements',
      resource_init: (x) => x.element_token,
    });

    setIsOpen(false);
    method.open(element_token);
  };

  const canAddLiabilityAccount = entity.entity.capabilities.includes('payments:receive');
  const onAddLiabilityAccount = async () => {
    const element_token = await AtomClient.createResource<string>({
      data: { entity_id: entity.entity.id, type: 'link', link: {} },
      resource_name: 'elements',
      resource_init: (x) => x.element_token,
    });

    setIsOpen(false);
    method.open(element_token);
  };

  const canAddACHAccount = entity.entity.capabilities.includes('payments:send');
  const onAddACHAccount = () => setIsCreateACHOpen(true);

  if (!canInitAuthSession && !canAddLiabilityAccount && !canAddACHAccount) return null;

  return (
    <EuiPopover
      id="thing"
      button={(
        <EuiButton
          fill
          iconType="boxesVertical"
          onClick={() => setIsOpen(!isOpen)}>
          Actions
        </EuiButton>
      )}
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
      panelPaddingSize="none"
      anchorPosition="downLeft">
      <CreateACHAccountModal
        entity={entity}
        isOpen={isCreateACHOpen}
        onClose={() => setIsCreateACHOpen(false)} />
      <EuiContextMenu
        initialPanelId={0}
        panels={[
          {
            id: 0,
            title: 'Entity actions',
            items: [
              canInitAuthSession && ({
                name: 'Create auth session',
                icon: 'lock',
                onClick: onCreateAuthSession,
              }),
              canAddLiabilityAccount && ({
                name: 'Add liability account',
                icon: 'indexOpen',
                onClick: onAddLiabilityAccount,
              }),
              canAddACHAccount && ({
                name: 'Add ACH account',
                icon: 'plusInCircle',
                onClick: onAddACHAccount,
              }),
            ].filter(Boolean),
          },
          // TODO: we can do panels
          // {
          //   id: 1,
          //   title: 'Another',
          //   items: [
          //     {
          //       name: 'handle on click',
          //       icon: 'search',
          //       onClick: onInitAuthSession,
          //     },
          //   ],
          // },
        ]} />
    </EuiPopover>
  );
}

export default RightContent;
