// @flow
import * as React from 'react';
import {
  EuiDescriptionList,
  EuiText,
  EuiIcon,
  EuiBadge,
} from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelOverview(props: TPanelDefaultProps): Node {
  const { account } = props;
  if (!account) return null;

  return (
    <Base.AccordionPanelContainer title="Overview">
      <Base.ContainerSplit
        equal
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Capabilities</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>
                      {account.account.capabilities.length > 0
                        ? account.account.capabilities.map((capability) => (
                          <EuiBadge key={capability}><code>{capability}</code></EuiBadge>
                        ))
                        : 'None'}
                    </h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Status</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>
                      <EuiIcon type="dot" color={account.account.get_color_for_status()} />
                      {' '}
                      {account.account.get_presentable_status()}
                    </h3>
                  </EuiText>
                ),
              },
            ]} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Created</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{account.account.get_presentable_created_at('MM/DD/YYYY, hh:mm A')}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Account ID</span></EuiText>,
                description: <EuiText size="xs"><h3>{account.account.id}</h3></EuiText>,
              },
            ]} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelOverview;
