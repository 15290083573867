// @flow
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import getEnv from './env';
import './index.css';
import DatadogLogger from './services/datadog-logger';

const env = getEnv();

DatadogLogger.init();

Sentry.init({
  dsn: env.sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  defaultIntegrations: false,
});

// eslint-disable-next-line no-console
console.log('Version:', process.env.REACT_APP_VERSION);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  window.document.getElementById('root'),
);
