// @flow
import type { TFIRUserOptions } from './types';

class FIRUser {
  uid: string;
  token: string;

  constructor(options: TFIRUserOptions) {
    this.uid = options.uid;
    this.token = options.token;
  }
}

export default FIRUser;
