// @flow
import firebase from 'firebase/app';
import 'firebase/auth';

import Cookies from 'js-cookie';
import getEnv from '../env';
import FIRUser from './FIRUser';
import { CookieKeys } from '../enums';

const env = getEnv();

class FIRClient {
  static init() {
    firebase.initializeApp(env.firebaseConfig);
  }

  static setEmailForSignIn(email: string): void {
    Cookies.set(CookieKeys.FIRClientEmailForSignIn, email);
  }

  static getEmailForSignIn(): ?string {
    return Cookies.get(CookieKeys.FIRClientEmailForSignIn) || null;
  }

  static removeEmailForSignIn(): void {
    Cookies.remove(CookieKeys.FIRClientEmailForSignIn);
  }

  static async sendLoginEmailAsync(email: string): Promise<boolean> {
    try {
      const actionCodeSettings = {
        url: `https://${env.dashDomain}/login`,
        handleCodeInApp: true,
      };
      await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
      FIRClient.setEmailForSignIn(email);
      return Promise.resolve(true);
    } catch (err) {
      return Promise.resolve(false);
    }
  }

  static isEmailAuthLink(link: string): boolean {
    return firebase.auth().isSignInWithEmailLink(link);
  }

  static async signInWithEmailLinkAsync(link: string): Promise<?FIRUser> {
    const email = FIRClient.getEmailForSignIn();
    if (email) {
      try {
        const { user } = await firebase.auth().signInWithEmailLink(email, link);
        const token = await user.getIdToken();

        FIRClient.removeEmailForSignIn();
        return new FIRUser({ uid: user.uid, token });
      } catch (err) {
        return Promise.resolve(null);
      }
    }

    return Promise.resolve(null);
  }
}

export default FIRClient;
