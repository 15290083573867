// @flow
import * as React from 'react';
import {
  EuiText,
  EuiBasicTable,
  EuiBadge, EuiAvatar,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';
import OctoPopulatedPayment from '../../../lib/OctoPopulatedPayment';
import * as hooks from '../../../hooks';
import { sizes } from '../../../styles';

function PanelRecentPayments(props: TPanelDefaultProps): Node {
  const { account } = props;
  if (!account) return null;

  const history = useHistory();
  const payments = hooks.usePayments({ page: 1, page_limit: 10, acc_id: account.account.id });

  const columns = [
    {
      render: (item: OctoPopulatedPayment) => item.payment.get_presentable_created_at(),
      name: 'Created',
      truncateText: true,
    },
    {
      render: (item: OctoPopulatedPayment) => (
        <>
          <Base.Container mr={sizes.s}>
            <EuiAvatar
              name="Bank"
              imageUrl={item.get_source_account_logo()}
              style={{ height: 25, width: 25 }} />
          </Base.Container>
          <EuiText size="xs">{item.get_presentable_source_account_name()}</EuiText>
        </>
      ),
      name: 'Source',
      truncateText: true,
    },
    {
      render: (item: OctoPopulatedPayment) => (
        <>
          <Base.Container mr={sizes.s}>
            <EuiAvatar
              name="Bank"
              imageUrl={item.get_destination_account_logo()}
              style={{ height: 25, width: 25 }} />
          </Base.Container>
          <EuiText size="xs">{item.get_presentable_destination_account_name()}</EuiText>
        </>
      ),
      name: 'Destination',
      truncateText: true,
    },
    {
      render: (item: OctoPopulatedPayment) => item.payment.get_presentable_amount(),
      name: 'Amount',
      truncateText: true,
    },
    {
      isExpander: true,
      render: (item: OctoPopulatedPayment) => (
        <EuiBadge color={item.payment.get_color_for_status()}>
          {item.payment.get_presentable_status()}
        </EuiBadge>
      ),
      name: 'Status',
      truncateText: true,
    },
  ];

  return (
    <Base.AccordionPanelContainer title="Recent Payments">
      <EuiBasicTable
        compressed
        tableLayout="auto"
        loading={!payments.completed}
        style={{ backgroundColor: 'transparent' }}
        items={payments.completed ? payments.data : []}
        columns={columns}
        rowProps={(item: OctoPopulatedPayment) => ({
          onClick: () => history.push(`/payments/${item.payment.id}`),
        })} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelRecentPayments;
