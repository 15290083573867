// @flow
import * as React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import type { Node } from 'react';
import * as Base from '../Base';
import * as hooks from '../../hooks';
import type { TRouteProps } from './types';

function CustomRoute(props: TRouteProps): Node {
  const { isProtected, ...rest } = props;
  const auth = hooks.useAuth();
  const history = useHistory();

  if (!auth.completed) return <Base.LoaderFill />;

  const authedAndProtected = isProtected && auth.user;
  const unAuthedAndNotProtected = !isProtected && !auth.user;
  const shouldCreateTeam = authedAndProtected
    && !auth.user.hasCompleteInfo()
    && history.location.pathname !== '/login/create_team';

  if (shouldCreateTeam) return <Redirect to={{ pathname: '/login/create_team' }} />;
  if (authedAndProtected || unAuthedAndNotProtected) return <Route {...rest} />;
  return <Redirect to={{ pathname: '/login' }} />;
}

export default CustomRoute;
