// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiText,
  EuiSpacer,
} from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelStepProps } from '../types';

function StepCreateDestinationAccount(props: TPanelStepProps): Node {
  return (
    <Base.Container>
      {/* Title + description */}
      <EuiText>
        <h3>Link a liability for your entity</h3>
        <p>
          Connect one of your user&apos;s liabilities (credit card, student loans, mortgage, etc.)
          As an example, we&apos;ll create a sample student loan liability and push funds to it.
        </p>
      </EuiText>

      <EuiSpacer />

      <Base.CodeBlock>
        {`curl https://dev.methodfi.com/accounts \\
    -X POST \\
    -H "Authorization: Bearer <DEV_API_KEY>" \\
    -H "Content-Type: application/json" \\
    -d '{
      "holder_id": "<ENTITY_ID>",
      "liability": {
        "mch_id": "mch_2",
        "account_number": "1122334455"
      }
    }'`}
      </Base.CodeBlock>
    </Base.Container>
  );
}

export default StepCreateDestinationAccount;
