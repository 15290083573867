// @flow
import * as React from 'react';
import { EuiCodeBlock } from '@elastic/eui';
import type { Node } from 'react';
import type { TCodeBlockProps } from './types';

function CodeBlock(props: TCodeBlockProps): Node {
  const { children, language } = props;

  return (
    <EuiCodeBlock
      isCopyable
      language={language || 'bash'}
      fontSize="s">
      {children}
    </EuiCodeBlock>
  );
}

export default CodeBlock;
