// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import { useParams } from 'react-router-dom';
import * as Layouts from '../components/Layouts';
import * as hooks from '../hooks';
import * as PaymentDetails from '../components/PaymentDetails';
import * as Base from '../components/Base';

function PaymentDetailsPage(): Node {
  const payment = hooks.usePayment(useParams().paymentId);

  return (
    <Layouts.Page withSidebar title="Payment Details">
      <PaymentDetails.PanelOverview payment={payment.data} />
      {payment.data
        && payment.data.payment.error
        && <Base.AccordionPanelError {...payment.data.payment.error} />}
      <PaymentDetails.PanelSource payment={payment.data} />
      <PaymentDetails.PanelDestination payment={payment.data} />
      <PaymentDetails.PanelMetadata payment={payment.data} />
      <PaymentDetails.PanelTraceIds payment={payment.data} />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(PaymentDetailsPage, { name: 'PaymentDetailsPage' });
