// @flow
import * as React from 'react';
import { EuiDescriptionList, EuiText } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelACH(props: TPanelDefaultProps): Node {
  const { account } = props;
  if (!account || !account.account.ach) return null;

  return (
    <Base.AccordionPanelContainer title="ACH Details">
      <Base.ContainerSplit
        align="flexStart"
        equal
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Routing Number</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{account.account.get_routing_number()}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Account Number</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{account.account.get_account_number()}</h3>
                  </EuiText>
                ),
              },
            ]} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Type</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{account.account.get_presentable_ach_type()}</h3>
                  </EuiText>
                ),
              },
            ]} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelACH;
