// @flow
import * as React from 'react';
import { EuiDescriptionList, EuiText } from '@elastic/eui';
import * as Base from './index';
import type { TAccordionPanelErrorProps } from './types';

function AccordionPanelError(props: TAccordionPanelErrorProps): Node {
  const {
    type,
    sub_type,
    code,
    message,
  } = props;

  return (
    <Base.AccordionPanelContainer title="Error">
      <Base.ContainerSplit
        equal
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Message</span></EuiText>,
                description: <EuiText size="xs"><h3>{message || 'None'}</h3></EuiText>,
              },
              {
                title: <EuiText size="xs"><span>Code</span></EuiText>,
                description: <EuiText size="xs"><h3>{code || 'None'}</h3></EuiText>,
              },
            ]} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Type</span></EuiText>,
                description: <EuiText size="xs"><h3>{type || 'None'}</h3></EuiText>,
              },
              {
                title: <EuiText size="xs"><span>Subtype</span></EuiText>,
                description: <EuiText size="xs"><h3>{sub_type || 'None'}</h3></EuiText>,
              },
            ]} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default AccordionPanelError;
