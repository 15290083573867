// @flow
import * as React from 'react';
import { EuiAvatar, EuiText } from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import { sizes } from '../../../styles';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelSource(props: TPanelDefaultProps): Node {
  const history = useHistory();
  const { payment } = props;
  if (!payment) return null;

  return (
    <Base.AccordionPanelContainer title="Source">
      <Base.ContainerSplit
        equal
        left={(
          <Base.Container flex align="center" onClick={() => history.push(`/entities/${payment.source_entity.id}`)}>
            <Base.Container mr={sizes.s}>
              <EuiAvatar
                name="source-entity"
                imageUrl={payment.source_entity.get_logo()}
                style={{ height: 40, width: 40 }} />
            </Base.Container>
            <EuiText size="xs">
              <h3 style={{ marginBottom: 0 }}>{payment.source_entity.get_presentable_name()}</h3>
              <span>{payment.source_entity.get_presentable_type()}</span>
            </EuiText>
          </Base.Container>
        )}
        right={(
          <Base.Container flex align="center" onClick={() => history.push(`/accounts/${payment.source_account.id}`)}>
            <Base.Container mr={sizes.s}>
              <EuiAvatar
                name="Bank"
                imageUrl={payment.get_source_account_logo()}
                style={{ height: 40, width: 40 }} />
            </Base.Container>
            <EuiText size="xs">
              <h3 style={{ marginBottom: 0 }}>{payment.get_presentable_source_account_name()}</h3>
              <span>{payment.source_account.get_presentable_type_with_mask()}</span>
            </EuiText>
          </Base.Container>
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelSource;
