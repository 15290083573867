// @flow
import { useState, useEffect } from 'react';
import AtomClient from '../lib/AtomClient';
import OctoPopulatedAccount from '../lib/OctoPopulatedAccount';

export default function usePayments(opts: any): { completed: boolean, data: Array<any> } {
  const [__, raiseError] = useState(null); // eslint-disable-line
  const [payments, setPayments] = useState({ completed: false });

  useEffect(() => {
    (async () => {
      setPayments({ completed: false });
      try {
        const response = await AtomClient.getPaginatedResource({
          resource_name: 'accounts',
          resource_init: OctoPopulatedAccount.init_from_raw,
          params: opts,
        });
        setPayments({ completed: true, ...response });
      } catch (error) {
        raiseError(() => { throw error; });
      }
    })();
  }, Object.values(opts));

  return payments;
}
