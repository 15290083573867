// @flow
import React, { useState, useEffect } from 'react';
import type { Node } from 'react';
import {
  EuiForm,
  EuiText,
  EuiSpacer,
  EuiButton,
  EuiCheckbox,
  EuiFormRow,
} from '@elastic/eui';
import type { TFormFieldProps, TFormProps, TFormSection } from './types';
import FormField from './FormField';
import FormFieldSplit from './FormFieldSplit';
import * as utils from '../../utils';

function Form(props: TFormProps): Node {
  const {
    description,
    onSubmit,
    sections,
    renderOnError,
    renderOnSuccess,
    verificationText,
    submitText,
    disableOnSuccess,
    readOnly,
  } = props;

  const fields = utils.flatMap((section) => section.fields, sections);

  // State
  const initialState = fields.reduce((acc, fieldGroup) => {
    const setValForField = (field: TFormFieldProps) => {
      if (field.options && field.options.length) acc[field.name] = field.options[0].value;
      else acc[field.name] = field.value || null;
    };

    if (Array.isArray(fieldGroup)) fieldGroup.forEach(setValForField);
    else setValForField(fieldGroup);

    return acc;
  }, {});
  const [isSubmitVerified, setIsSubmitVerified] = useState(Boolean(!verificationText));
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [formSections, setFormSections] = useState(sections);
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  // Methods
  function _getFieldValidations(_fields: Array<Array<TFormFieldProps> | TFormFieldProps>): Array<Array<TFormFieldProps> | TFormFieldProps> {
    return _fields.map((fieldGroup) => {
      // $FlowFixMe
      if (Array.isArray(fieldGroup)) return _getFieldValidations(fieldGroup);
      return {
        ...fieldGroup,
        isInvalid: (fieldGroup.validator && !fieldGroup.optional) ? !fieldGroup.validator(formData[fieldGroup.name]) : false,
      };
    });
  }

  function _getSectionValidations(_sections: Array<TFormSection>): Array<TFormSection> {
    return _sections.map((section) => ({ ...section, fields: _getFieldValidations(section.fields) }));
  }

  const _onChange = (key: string) => (value: any) => setFormData({ ...formData, [key]: value || null });
  const _enhanceField = (field: TFormFieldProps): TFormFieldProps => ({
    ...field,
    key: field.name,
    isDisabled: isLoading || (success && disableOnSuccess) || readOnly,
    onChange: _onChange(field.name),
    fullWidth: true,
  });

  const _onSubmit = (event) => {
    if (onSubmit) {
      event.preventDefault();
      setSubmitAttempted(true);
      const sectionsWithValidation = _getSectionValidations(formSections);
      const allValid = utils.flatten(
        utils.flatMap((section) => section.fields, sectionsWithValidation),
      ).every((field) => !field.isInvalid);

      if (allValid) {
        setIsLoading(true);
        onSubmit(formData).then(setSuccess).catch(setError).finally(() => setIsLoading(false));
      } else {
        setFormSections(sectionsWithValidation);
      }
    }
  };

  // Hooks
  useEffect(() => {
    if (submitAttempted) setFormSections(_getSectionValidations(formSections));
  }, [formData]);

  return (
    <EuiForm component="form" onSubmit={_onSubmit}>
      {description && [
        <EuiText key="form-desc"><p>{description}</p></EuiText>,
        <EuiSpacer key="form-spacer" />,
      ]}
      {success && renderOnSuccess && renderOnSuccess()}
      {error && renderOnError && renderOnError(error)}
      {formSections.map((section) => (
        <div key={section.key}>
          {section.title && (
            <EuiText>
              <h4>{section.title}</h4>
              <EuiSpacer size="s" />
            </EuiText>
          )}
          {section.fields.map((fieldGroup) => {
            if (Array.isArray(fieldGroup)) {
              const [left, right] = fieldGroup.map(_enhanceField);
              return <FormFieldSplit key={`${left.name}-${right.name}`} left={left} right={right} />;
            }
            return <FormField {..._enhanceField(fieldGroup)} key={fieldGroup.name} />;
          })}
          <EuiSpacer />
        </div>
      ))}

      {verificationText && (
        <EuiFormRow fullWidth>
          <EuiCheckbox
            id={Math.random.toString()}
            label={verificationText}
            checked={isSubmitVerified}
            disabled={isLoading}
            onChange={() => setIsSubmitVerified(!isSubmitVerified)} />
        </EuiFormRow>
      )}
      {submitText && (
        <EuiButton
          fullWidth
          iconType="arrowRight"
          iconSide="right"
          type="submit"
          fill
          isLoading={isLoading}
          isDisabled={isLoading || !isSubmitVerified || (success && disableOnSuccess)}>
          {submitText}
        </EuiButton>
      )}
    </EuiForm>
  );
}

export default Form;
