// @flow
import React from 'react';
import type { Node } from 'react';
import type { TModalSupportProps } from './types';
import * as Base from '../Base';
import * as hooks from '../../hooks';
import { sizes } from '../../styles';
import type { TCardProps } from '../Base/types';
import ChatlioClient from '../../lib/ChatlioClient';

function ModalSupport(props: TModalSupportProps): Node {
  const user = hooks.useUser();
  const { isOpen, onClose } = props;
  if (!isOpen) return null;

  const cards: Array<TCardProps & { key: string }> = [
    {
      key: 'sched_call',
      icon: 'calendar',
      title: 'Schedule a call',
      description: 'Chat with Method\'s integration team at your convenience.',
      onClick: () => user.onSupportCall(),
    },
    {
      key: 'email_us',
      icon: 'email',
      title: 'Send us an email',
      description: 'Our team usually responds in a couple of hours.',
      onClick: () => window.open('mailto:team@methodfi.com'),
    },
    {
      key: 'chat_with_team',
      icon: 'user',
      title: 'Chat with founders',
      description: 'Live chat with our founding team.',
      onClick: () => {
        ChatlioClient.open();
        onClose();
      },
    },
  ];

  return (
    <Base.Modal
      title="We're here to help!"
      isOpen={isOpen}
      actions={[{ closeOnClick: true, text: 'Cancel', color: 'text' }]}
      onClose={onClose}>
      {cards.map(({ key, ...rest }) => (
        <Base.Container key={key} mb={sizes.m}>
          <Base.Card {...rest} />
        </Base.Container>
      ))}
    </Base.Modal>
  );
}

export default ModalSupport;
