// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import * as Layouts from '../components/Layouts';
import * as Merchants from '../components/Merchants';

function MerchantsPage(): Node {
  return (
    <Layouts.Page withSidebar title="Merchants">
      <Merchants.PanelTable />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(MerchantsPage, { name: 'MerchantsPage' });
