// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import {
  EuiPanel,
  EuiSteps,
  EuiSpacer,
} from '@elastic/eui';
import StepContainer from './StepContainer';
import StepIntro from './StepIntro';
import StepGetAPIKeys from './StepGetAPIKeys';
import StepCreateEntity from './StepCreateEntity';
import StepCreateSourceAccount from './StepCreateSourceAccount';
import StepCreateDestinationAccount from './StepCreateDestinationAccount';
import StepSendPayment from './StepSendPayment';
import StepApplyForProduction from './StepApplyForProduction';

import type { TPanelStepsProps } from '../types';

const steps = [
  { title: 'Welcome to Method!', StepComponent: StepIntro },
  { title: 'Get your API Keys', StepComponent: StepGetAPIKeys },
  { title: 'Create an entity', StepComponent: StepCreateEntity },
  { title: 'Create a source account', StepComponent: StepCreateSourceAccount },
  { title: 'Create a destination account', StepComponent: StepCreateDestinationAccount },
  { title: 'Send a payment', StepComponent: StepSendPayment },
  { title: 'Apply for Production', StepComponent: StepApplyForProduction },
].map((step) => ({ ...step, key: Math.random().toString() }));

function PanelSteps(props: TPanelStepsProps): Node {
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const hasNext = () => currentStepIdx + 1 < steps.length;
  const hasPrev = () => currentStepIdx - 1 >= 0;
  const onBack = () => hasPrev() && setCurrentStepIdx(currentStepIdx - 1);
  const onNext = () => hasNext() && setCurrentStepIdx(currentStepIdx + 1);

  return (
    <EuiPanel paddingSize="none" color="subdued" borderRadius="none">
      <EuiSpacer />
      <EuiSteps
        titleSize="xs"
        steps={steps.map(({ StepComponent, ...step }, idx) => ({
          title: step.title,
          status: idx > currentStepIdx
            ? 'incomplete'
            : idx === currentStepIdx ? 'active' : 'complete',
          children: idx === currentStepIdx && (
            <StepContainer
              hasNext={hasNext()}
              hasPrev={hasPrev()}
              onBack={onBack}
              onNext={onNext}>
              <StepComponent />
            </StepContainer>
          ),
        }))} />
    </EuiPanel>
  );
}

export default PanelSteps;
