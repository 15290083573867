// @flow
export type TEnv = {
  sentryDSN: string,
  atomURL: string,
  dashDomain: string,
  firebaseConfig: {},
}

const env = process.env.REACT_APP_FIREBASE_CONFIG_TYPE || 'local';

export default function getEnv(): TEnv {
  const local = {
    sentryDSN: '',
    atomURL: 'http://localhost:8084',
    dashDomain: 'marcogradjoy.ngrok.io',
    firebaseConfig: {
      apiKey: 'AIzaSyC-S0RmsIn2WGdCLXDtCX5UeqS0XWBvf8Y',
      authDomain: 'test-method-dashboard.firebaseapp.com',
      projectId: 'test-method-dashboard',
      storageBucket: 'test-method-dashboard.appspot.com',
      messagingSenderId: '545922243730',
      appId: '1:545922243730:web:ef113f4261061aa0885a4b',
      measurementId: 'G-ZGFTTF2XEB',
    },
    dd_logger_config: { context: { env: 'dash-dev1' } },
  };
  const dev = {
    sentryDSN: 'https://4868e41989dd4844af8e5e5056d31eab@o488647.ingest.sentry.io/5732039',
    atomURL: 'https://atom.dev.methodfi.com',
    dashDomain: 'dashboard.dev.methodfi.com',
    firebaseConfig: {
      apiKey: 'AIzaSyBAx36iLwSQfzki-PkqRpUqbU7UxO-nBu8',
      authDomain: 'method-dash-dev.firebaseapp.com',
      projectId: 'method-dash-dev',
      storageBucket: 'method-dash-dev.appspot.com',
      messagingSenderId: '162848460371',
      appId: '1:162848460371:web:693f40b957153c4439c0a6',
      measurementId: 'G-RWS9ECDMVQ',
    },
    dd_logger_config: { context: { env: 'dash-dev1' } },
  };
  const production = {
    sentryDSN: 'https://dc94e8a5b89043f291f64d0c958a7673@o488647.ingest.sentry.io/5732040',
    atomURL: 'https://atom.production.methodfi.com',
    dashDomain: 'dashboard.methodfi.com',
    firebaseConfig: {
      apiKey: 'AIzaSyD2LepEnjpMUnWIo_7dh81Vw5aq4tzVOXk',
      authDomain: 'method-dash.firebaseapp.com',
      projectId: 'method-dash',
      storageBucket: 'method-dash.appspot.com',
      messagingSenderId: '581825960045',
      appId: '1:581825960045:web:c06b7600638eb5620b8764',
      measurementId: 'G-3JBLLLKGGV',
    },
    dd_logger_config: { context: { env: 'dash-prod1' } },
  };
  return ({ local, dev, production })[env];
}
