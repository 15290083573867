// @flow
import Team from './Team';
import AtomClient from './AtomClient';
import type {
  TUserOptions,
  TUserPurposes,
  TUserRawOptions,
  TUserRoles,
} from './types';

class User {
  id: string;
  first_name: ?string;
  last_name: ?string;
  email: string;
  phone: ?string;
  purpose: ?TUserPurposes;
  role: ?TUserRoles;
  teams: Array<Team>;

  constructor(options: TUserOptions) {
    this.id = options.id;
    this.first_name = options.first_name;
    this.last_name = options.last_name;
    this.email = options.email;
    this.phone = options.phone;
    this.purpose = options.purpose;
    this.role = options.role;
    this.teams = options.teams;
  }

  static init(options: TUserRawOptions): User {
    return new User({
      ...options,
      teams: options.teams.map(Team.init),
    });
  }

  static async logoutAsync(): Promise<void> {
    AtomClient.clearAuthToken();
    Team.clearActiveTeamId();

    window.location.reload();
  }

  async loadTeamAsync(team_id?: string): Promise<User> {
    if (this.teams.length === 0) return this;

    let id = team_id || Team.getActiveTeamId() || this.teams[0].id;

    // Fake Team = Default to first team
    const teamExists = this.teams.some((team) => team.id === id);
    if (!teamExists) id = this.teams[0].id;

    Team.setActiveTeamId(id);

    const activeTeam = Team.init(await AtomClient.getTeam(id));
    this.teams = this.teams.map((team) => (team.id === activeTeam.id ? activeTeam : team));

    return this;
  }

  hasCompleteInfo(): boolean {
    return Boolean(
      this.first_name
      && this.last_name
      && this.email
      && this.phone
      && this.purpose
      && this.role
      && this.teams.length > 0,
    );
  }

  getActiveTeam(): Team {
    return this.teams.find((team) => team.isActive) || this.teams[0];
  }

  onApplyForLiveSandbox(): void {
    // eslint-disable-next-line max-len
    window.open(
      `https://calendly.com/methodfi/vip-production-onboarding/?name=${this.first_name || ''}%20${this.last_name || ''}&email=${this.email}`,
      '_blank',
    );
  }

  onApplyForProduction(): void {
    // eslint-disable-next-line max-len
    window.open(
      `https://calendly.com/methodfi/vip-production-onboarding/?name=${this.first_name || ''}%20${this.last_name || ''}&email=${this.email}`,
      '_blank',
    );
  }

  onSupportCall(): void {
    // eslint-disable-next-line max-len
    window.open(
      `https://calendly.com/methodfi/vip-support-call/?name=${this.first_name || ''}%20${this.last_name || ''}&email=${this.email}`,
      '_blank',
    );
  }
}

export default User;
