// @flow
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AtomClient from '../lib/AtomClient';
import OctoPopulatedAccount from '../lib/OctoPopulatedAccount';

export default function useAccount(id: string): { completed: boolean, data: null } {
  const [__, raiseError] = useState(null); // eslint-disable-line
  const [account, setAccount] = useState({ completed: false });
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setAccount({
          completed: true,
          data: await AtomClient.getResource({
            id,
            resource_name: 'accounts',
            resource_init: OctoPopulatedAccount.init_from_raw,
          }),
        });
      } catch (error) {
        history.push('/accounts');
        raiseError(() => { throw error; });
      }
    })();
  }, []);

  return account;
}
