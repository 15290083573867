// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import * as Layouts from '../components/Layouts';
import * as Accounts from '../components/Accounts';

function AccountsPage(): Node {
  return (
    <Layouts.Page withSidebar title="Accounts">
      <Accounts.PanelTable />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(AccountsPage, { name: 'AccountsPage' });
