// @flow
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import type { Node } from 'react';
import moment from 'moment';
import * as change_case from 'change-case';
import {
  EuiBasicTable,
  EuiSpacer,
  EuiAvatar,
  EuiText,
  EuiBadge,
  EuiFormRow,
  EuiFormControlLayout,
  EuiDatePicker,
} from '@elastic/eui';
import * as Base from '../../Base';
import { sizes } from '../../../styles';
import type { TPanelTableProps } from '../types';
import { useEntities } from '../../../hooks';
import OctoPopulatedEntity from '../../../lib/OctoPopulatedEntity';
import { FormFieldTypes } from '../../../enums';
import { OctoEntityStatuses, OctoEntityTypes } from '../../../enums/OctoEntity';

function PanelTable(props: TPanelTableProps): Node {
  const history = useHistory();
  const [filter, set_filter] = React.useState({
    page: 1,
    page_limit: 10,
    type: null,
    status: null,
    to_date: null,
    from_date: null,
    name: null,
  });
  const entities = useEntities(filter);

  const columns = [
    {
      render: (item: OctoPopulatedEntity) => item.entity.get_presentable_created_at(),
      name: 'Created',
      truncateText: true,
    },
    {
      render: (item: OctoPopulatedEntity) => (
        <>
          <Base.Container mr={sizes.s}>
            <EuiAvatar
              name="Bank"
              imageUrl={item.entity.get_logo()}
              style={{ height: 25, width: 25 }} />
          </Base.Container>
          <EuiText size="xs">{item.entity.get_presentable_name()}</EuiText>
        </>
      ),
      name: 'Name',
      truncateText: true,
    },
    {
      render: (item: OctoPopulatedEntity) => item.entity.get_presentable_type(),
      name: 'Type',
      truncateText: true,
    },
    {
      isExpander: true,
      render: (item: OctoPopulatedEntity) => (
        <EuiBadge color={item.entity.get_color_for_status()}>
          {item.entity.get_presentable_status()}
        </EuiBadge>
      ),
      name: 'Status',
      truncateText: true,
    },
  ];

  const pagination = {
    pageIndex: filter.page - 1,
    pageSize: filter.page_limit,
    totalItemCount: entities.total_count,
    pageSizeOptions: [10, 25, 50],
  };

  return (
    <Base.Container>
      <Base.Container flex align="center">
        {/* Start Date */}
        <Base.Container mr={sizes.s}>
          <EuiFormRow label="Start Date">
            <EuiFormControlLayout>
              <EuiDatePicker
                placeholder="Select a Date"
                selected={filter.from_date ? moment(filter.from_date) : null}
                onChange={(date) => date.isValid() && set_filter({ ...filter, from_date: date.format('YYYY-MM-DD') })}
                onClear={() => set_filter({ ...filter, from_date: null })} />
            </EuiFormControlLayout>
          </EuiFormRow>
        </Base.Container>
        {/* End Date */}
        <Base.Container mr={sizes.s}>
          <EuiFormRow label="End Date">
            <EuiFormControlLayout>
              <EuiDatePicker
                placeholder="Select a Date"
                selected={filter.to_date ? moment(filter.to_date) : null}
                onChange={(date) => date.isValid() && set_filter({ ...filter, to_date: date.format('YYYY-MM-DD') })}
                onClear={() => set_filter({ ...filter, to_date: null })} />
            </EuiFormControlLayout>
          </EuiFormRow>
        </Base.Container>
        {/* Status */}
        <Base.Container mr={sizes.s}>
          <Base.FormField
            name="status"
            label="Status"
            value={filter.status}
            type={FormFieldTypes.Select}
            options={[{ text: 'All', value: '' }, ...Object.values(OctoEntityStatuses).map((status) => ({
              text: change_case.capitalCase(status),
              value: status,
            }))]}
            onChange={(status) => set_filter({ ...filter, status: status || null })} />
        </Base.Container>
        {/* Type */}
        <Base.Container mr={sizes.s}>
          <Base.FormField
            name="type"
            label="Type"
            value={filter.type}
            type={FormFieldTypes.Select}
            options={[{ text: 'All', value: '' }, ...Object.values(OctoEntityTypes).map((type) => ({
              text: change_case.capitalCase(type),
              value: type,
            }))]}
            onChange={(type) => set_filter({ ...filter, type: type || null })} />
        </Base.Container>
        {/* Name */}
        <Base.Container mr={sizes.s}>
          <Base.FormField
            name="name"
            label="Name"
            placeholder="Kevin Doyle"
            type={FormFieldTypes.Text}
            onChange={(name) => set_filter({ ...filter, name: name || null })} />
        </Base.Container>
      </Base.Container>

      <EuiSpacer />

      <EuiBasicTable
        compressed
        loading={!entities.completed}
        style={{ backgroundColor: 'transparent' }}
        items={entities.completed ? entities.data : []}
        columns={columns}
        pagination={pagination}
        rowProps={(item: OctoPopulatedEntity) => ({
          onClick: () => history.push(`/entities/${item.entity.id}`),
        })}
        onChange={(event) => {
          set_filter({ ...filter, page: event.page.index + 1, page_limit: event.page.size });
        }} />
    </Base.Container>
  );
}

export default PanelTable;
