// @flow
import axios from 'axios';
import Cookies from 'js-cookie';
import { CookieKeys } from '../enums';
import type { TTeamRawOptions, TUpdateUserInfoOptions, TUserRawOptions } from './types';
import getEnv from '../env';
import User from './User';
import DatadogLogger from '../services/datadog-logger';
import { DatadogLoggerNames } from '../services/datadog-logger/types';
import Team from './Team';

const env = getEnv();

class AtomClient {
  static getAuthToken(): ?string {
    return Cookies.get(CookieKeys.AtomToken) || null;
  }

  static hasAuthToken(): boolean {
    return Boolean(AtomClient.getAuthToken());
  }

  static clearAuthToken(): void {
    Cookies.remove(CookieKeys.AtomToken);
  }

  static setAuthToken(token: string): void {
    Cookies.set(CookieKeys.AtomToken, token);
  }

  static async request(options: Object): Promise<any> {
    const token = AtomClient.getAuthToken();
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    const dd_logger = new DatadogLogger({ name: DatadogLoggerNames.AtomClient });

    dd_logger.info('Atom API - Request', options);

    try {
      return await axios({ ...options, headers });
    } catch (error) {
      if (error.response) {
        dd_logger.warn('Atom API - Error Response', error.response);
        const isUnAuthed = [401, 403].includes(error.response.status);
        if (isUnAuthed) await User.logoutAsync();
      }

      dd_logger.error('Dashboard App - Unexpected error', error);
      throw error;
    }
  }

  static getURL(path: string): string {
    return `${env.atomURL}${path}`;
  }

  static async getUser(): Promise<TUserRawOptions> {
    return (await AtomClient.request({
      url: AtomClient.getURL('/users/me'),
      method: 'GET',
    })).data.data;
  }

  static async updateUser(options: TUpdateUserInfoOptions): Promise<TUserRawOptions> {
    return (await AtomClient.request({
      url: AtomClient.getURL('/users/me'),
      method: 'PUT',
      data: options,
    })).data.data;
  }

  static async getTeam(team_id: string): Promise<TTeamRawOptions> {
    return (await AtomClient.request({
      url: AtomClient.getURL(`/teams/${team_id}`),
      method: 'GET',
    })).data.data;
  }

  static async loginWithFIRToken(fir_token: string): Promise<void> {
    const { token } = (await AtomClient.request({
      url: AtomClient.getURL('/auth/login/fir_token'),
      method: 'POST',
      data: { fir_token },
    })).data.data;

    AtomClient.setAuthToken(token);
  }

  static async getPaginatedResource<Resource>(opts: {
    resource_name: string,
    resource_init: (raw_opts: any) => Resource,
    params: { page: number, page_limit: number },
  }): Promise<{
    data: Array<Resource>,
    page: number,
    page_count: number,
    page_limit: number,
    total_count: number,
  }> {
    const active_team_id = Team.getActiveTeamId();
    const active_env = Team.getActiveEnv();

    const response = await AtomClient.request({
      url: AtomClient.getURL(`/teams/${active_team_id}/${active_env}/${opts.resource_name}`),
      method: 'GET',
      params: opts.params,
    });

    return {
      data: response.data.data.map(opts.resource_init),
      page: Number(response.headers['pagination-page']),
      page_count: Number(response.headers['pagination-page-count']),
      page_limit: Number(response.headers['pagination-page-limit']),
      total_count: Number(response.headers['pagination-total-count']),
    };
  }

  static getTeamPath(): string {
    const active_team_id = Team.getActiveTeamId();
    const active_env = Team.getActiveEnv();

    return `/teams/${active_team_id}/${active_env}`;
  }

  static async getResource<Resource>(opts: {
    id: string,
    resource_name: string,
    resource_init: (raw_opts: any) => Resource,
  }): Promise<Resource> {
    const resource = (await AtomClient.request({
      url: AtomClient.getURL(`${AtomClient.getTeamPath()}/${opts.resource_name}/${opts.id}`),
      method: 'GET',
    })).data.data;

    return opts.resource_init(resource);
  }

  static async createResource<Resource>(opts: {
    data: any,
    resource_name: string,
    resource_init: (raw_opts: any) => Resource,
  }): Promise<Resource> {
    const resource = (await AtomClient.request({
      url: AtomClient.getURL(`${AtomClient.getTeamPath()}/${opts.resource_name}`),
      method: 'POST',
      data: opts.data,
    })).data.data;

    return opts.resource_init(resource);
  }

  static async createTeam(options: { name: string }): Promise<any> {
    return (await AtomClient.request({
      url: AtomClient.getURL('/teams'),
      method: 'POST',
      data: options,
    })).data.data;
  }

  static async getTeams(): Promise<any> {
    return (await AtomClient.request({
      url: AtomClient.getURL('/teams'),
      method: 'GET',
    })).data.data;
  }
}

export default AtomClient;
