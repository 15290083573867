// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import * as Layouts from '../components/Layouts';
import * as Dashboard from '../components/Dashboard';

function DashboardPage(): Node {
  return (
    <Layouts.Page withSidebar title="Get started with Method">
      <Dashboard.PanelSteps />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(DashboardPage, { name: 'DashboardPage' });
