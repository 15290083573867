// @flow
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AtomClient from '../lib/AtomClient';
import OctoPopulatedEntity from '../lib/OctoPopulatedEntity';

export default function useEntity(id: string): { completed: boolean, data: null } {
  const [__, raiseError] = useState(null); // eslint-disable-line
  const [entity, setEntity] = useState({ completed: false });
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setEntity({
          completed: true,
          data: await AtomClient.getResource({
            id,
            resource_name: 'entities',
            resource_init: OctoPopulatedEntity.init_from_raw,
          }),
        });
      } catch (error) {
        history.push('/entities');
        raiseError(() => { throw error; });
      }
    })();
  }, []);

  return entity;
}
