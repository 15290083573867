// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiText,
  EuiSpacer,
} from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelStepProps } from '../types';

function StepCreateSourceAccount(props: TPanelStepProps): Node {
  return (
    <Base.Container>
      {/* Title + description */}
      <EuiText>
        <h3>Link a bank account for your entity</h3>
        <p>
          Connect your user&apos;s checking / savings account
          to Method. As an example, we&apos;ll pull the funds from this
          account and send it to the destination account.
        </p>
      </EuiText>

      <EuiSpacer />

      <Base.CodeBlock>
        {`curl https://dev.methodfi.com/accounts \\
    -X POST \\
    -H "Authorization: Bearer <DEV_API_KEY>" \\
    -H "Content-Type: application/json" \\
    -d '{
      "holder_id": "<ENTITY_ID>",
      "ach": {
        "routing": "367537407",
        "number": "57838927",
        "type": "checking"
      }
    }'`}
      </Base.CodeBlock>
    </Base.Container>
  );
}

export default StepCreateSourceAccount;
