// @flow
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AtomClient from '../lib/AtomClient';
import OctoMerchant from '../lib/OctoMerchant';

export default function useMerchant(id: string): { completed: boolean, data: null } {
  const [__, raiseError] = useState(null); // eslint-disable-line
  const [merchant, setMerchant] = useState({ completed: false });
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setMerchant({
          completed: true,
          data: await AtomClient.getResource({
            id,
            resource_name: 'merchants',
            resource_init: OctoMerchant.init_from_raw,
          }),
        });
      } catch (error) {
        history.push('/merchants');
        raiseError(() => { throw error; });
      }
    })();
  }, []);

  return merchant;
}
