// @flow
import * as React from 'react';
import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  EuiButton,
  EuiModalBody,
  EuiText,
} from '@elastic/eui';
import type { Node } from 'react';
import type { TModalProps } from './types';

function Modal(props: TModalProps): Node {
  const {
    isOpen,
    title,
    actions,
    onClose,
    children,
  } = props;

  if (!isOpen) return null;

  return (
    <EuiModal onClose={onClose}>
      {/* Title */}
      {title && (
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <EuiText>
              <h3>{title}</h3>
            </EuiText>
          </EuiModalHeaderTitle>
        </EuiModalHeader>
      )}

      {/* Content */}
      <EuiModalBody>{children}</EuiModalBody>

      {/* Footer */}
      {actions && actions.length > 0 && (
        <EuiModalFooter>
          {actions.map((action) => (
            <EuiButton
              key={action.text}
              fill={action.fill}
              color={action.color}
              onClick={() => {
                if (action.onClick) action.onClick();
                if (action.closeOnClick) onClose();
              }}>
              {action.text}
            </EuiButton>
          ))}
        </EuiModalFooter>
      )}
    </EuiModal>

  );
}

export default Modal;
