// @flow
import React from 'react';
import type { Node } from 'react';
import { EuiCallOut } from '@elastic/eui';
import * as Base from '../Base';
import type { TFormEmailLink } from './types';
import FIRClient from '../../lib/FIRClient';
import * as validators from '../../validators';
import { sizes } from '../../styles';
import { FormFieldTypes } from '../../enums';

function FormEmailLink(props: TFormEmailLink): Node {
  const onSubmit = async ({ email }) => FIRClient.sendLoginEmailAsync(email);
  const formSections = [
    {
      key: 'email',
      fields: [
        {
          type: FormFieldTypes.Text,
          fullWidth: true,
          name: 'email',
          label: 'Email',
          placeholder: 'team@methodfi.com',
          validator: (email) => !validators.base.email.validate(email).error,
        },
      ],
    },
  ];

  return (
    <Base.Form
      disableOnSuccess
      sections={formSections}
      onSubmit={onSubmit}
      submitText="Continue"
      renderOnSuccess={() => (
        <Base.Container mb={sizes.xl}>
          <EuiCallOut title="Check your email" iconType="email" color="success">
            <p>Click the link in your email to access your Method Dashboard</p>
          </EuiCallOut>
        </Base.Container>
      )} />
  );
}

export default FormEmailLink;
