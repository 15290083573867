// @flow
import * as change_case from 'change-case';
import OctoAccount from './OctoAccount';
import OctoEntity from './OctoEntity';
import OctoRoutingNumber from './OctoRoutingNumber';
import OctoMerchant from './OctoMerchant';
import { OctoAccountTypes } from '../enums/OctoAccount';

class OctoPopulatedAccount {
  account: OctoAccount;
  holder: OctoEntity
  routing_number: ?OctoRoutingNumber;
  merchant: ?OctoMerchant;

  constructor(opts: any) {
    this.account = opts.account;
    this.holder = opts.holder;
    this.routing_number = opts.routing_number;
    this.merchant = opts.merchant;
  }

  static init_from_raw(raw_opts: any): OctoPopulatedAccount {
    return new OctoPopulatedAccount({
      account: new OctoAccount(raw_opts.account),
      holder: new OctoEntity(raw_opts.holder),
      routing_number: raw_opts.routing_number ? new OctoRoutingNumber(raw_opts.routing_number) : null,
      merchant: raw_opts.merchant ? new OctoMerchant(raw_opts.merchant) : null,
    });
  }

  get_presentable_name(): string {
    let name;

    switch (this.account.type) {
      case OctoAccountTypes.ach: {
        name = this.routing_number
          ? this.routing_number.institution_name
          : `${this.account.ach.type} - ${this.account.ach.number.substr(-4)}`;
        break;
      }
      case OctoAccountTypes.clearing: {
        name = `Clearing - ${this.account.clearing.number.substr(-4)}`;
        break;
      }
      case OctoAccountTypes.liability: {
        name = this.merchant
          ? this.merchant.name
          : `Liability - ${this.account.liability.mask}`;
        const liability_data = this.account.get_liability_data();
        if (liability_data) name = liability_data.name;
        break;
      }
      default: name = 'Unnamed Account';
    }

    return change_case.capitalCase(name);
  }

  get_logo(): string {
    if (this.routing_number && this.routing_number.logo) return this.routing_number.logo;
    if (this.merchant && this.merchant.logo) return this.merchant.logo;
    return 'https://static.methodfi.com/brand/default-fi-icon.png';
  }

  can_receive_payments(): boolean {
    return this.holder.capabilities.includes('payments:receive')
      && this.account.capabilities.includes('payments:receive');
  }

  can_send_payments(): boolean {
    return this.holder.capabilities.includes('payments:send')
      && this.account.capabilities.includes('payments:send');
  }
}

export default OctoPopulatedAccount;
