// @flow
import * as React from 'react';
import type { Node } from 'react';
import { EuiCallOut } from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import * as Base from '../../Base';
import type { TCreateACHAccountModalProps } from '../types';
import { sizes } from '../../../styles';
import { FormFieldTypes } from '../../../enums';
import { OctoAccountACHTypes } from '../../../enums/OctoAccount';
import AtomClient from '../../../lib/AtomClient';
import OctoAccount from '../../../lib/OctoAccount';

function CreateACHAccountModal(props: TCreateACHAccountModalProps): Node {
  const {
    entity,
    isOpen,
    onClose,
  } = props;
  const history = useHistory();

  const formSections = [
    {
      key: 'ach',
      fields: [
        {
          label: 'Type',
          name: 'type',
          type: FormFieldTypes.Select,
          options: [
            { text: 'Checking', value: OctoAccountACHTypes.checking },
            { text: 'Savings', value: OctoAccountACHTypes.savings },
          ],
        },
        {
          label: 'Routing number',
          name: 'routing',
          type: FormFieldTypes.Text,
          minLength: 9,
          maxLength: 9,
          validator: (routing) => /^\d{9}$/.test(routing),
        },
        {
          label: 'Account number',
          name: 'number',
          type: FormFieldTypes.Text,
          maxLength: 17,
          validator: (number) => /^\d{1,17}$/.test(number),
        },
      ],
    },
  ];

  const onSubmit = async (formData) => {
    const octo_account = await AtomClient.createResource<OctoAccount>({
      data: { holder_id: entity.entity.id, ach: formData },
      resource_name: 'accounts',
      resource_init: OctoAccount.init_from_raw,
    });

    history.push(`/accounts/${octo_account.id}`);
  };

  return (
    <Base.Modal
      title="New ACH account"
      isOpen={isOpen}
      onClose={onClose}>
      <Base.Form
        sections={formSections}
        onSubmit={onSubmit}
        submitText="Connect"
        renderOnError={() => (
          <Base.Container mb={sizes.m}>
            <EuiCallOut title="Invalid account information." iconType="alert" color="danger">
              <p>Please verify the account&apos;s information and try again.</p>
            </EuiCallOut>
          </Base.Container>
        )} />
    </Base.Modal>
  );
}

export default CreateACHAccountModal;
