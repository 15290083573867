// @flow
import * as React from 'react';
import { EuiBasicTable } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelCorporation(props: TPanelDefaultProps): Node {
  const { entity } = props;
  if (!entity || !entity.entity.corporation) return null;

  const owners_columns = [
    { field: 'name', name: 'Name' },
    { field: 'phone', name: 'Phone' },
    { field: 'email', name: 'Email' },
    { field: 'dob', name: 'Date of Birth' },
    { field: 'address', name: 'Address' },
  ];

  return (
    <Base.AccordionPanelContainer title="Corporation Owners">
      <EuiBasicTable
        compressed
        style={{ backgroundColor: 'transparent' }}
        items={entity.entity.get_presentable_owners()}
        columns={owners_columns} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelCorporation;
