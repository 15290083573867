// @flow
import { datadogLogs } from '@datadog/browser-logs';
import { DatadogLoggerNames } from './types';
import type {
  TDatadogLoggerNames,
  TDatadogLoggerOptions,
} from './types';
import getEnv from '../../env';

export default class DatadogLogger {
  name: TDatadogLoggerNames;

  constructor(opts: TDatadogLoggerOptions) {
    this.name = opts.name;
    this._logger = datadogLogs.getLogger(opts.name);
    this.debug = (...args) => this._logger.debug(...args);
    this.info = (...args) => this._logger.info(...args);
    this.warn = (...args) => this._logger.warn(...args);
    this.error = (...args) => this._logger.error(...args);
  }

  static init(): void {
    const config = getEnv().dd_logger_config;

    // Init Logger
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DD_TOKEN,
      site: 'datadoghq.com',
      sampleRate: 100,
      service: 'dash',
      env: config.context.env,
    });

    // Create Loggers
    datadogLogs.createLogger(DatadogLoggerNames.Navigation, { handler: 'http', ...config });
    datadogLogs.createLogger(DatadogLoggerNames.AtomClient, { handler: 'http', ...config });
  }
}
