// @flow
import User from './User';

class ChatlioClient {
  static isLoaded(): boolean {
    return Boolean(window._chatlio);
  }

  static configure(): void {
    if (ChatlioClient.isLoaded()) {
      window._chatlio.configure({});
    }
  }

  static initWithUser(user: User): void {
    if (ChatlioClient.isLoaded()) {
      ChatlioClient.identify(user.id, {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        purpose: user.purpose,
        role: user.role,
      });
      ChatlioClient.show();
    }
  }

  static isShown(): boolean {
    if (ChatlioClient.isLoaded()) {
      return window._chatlio.isShown();
    }
    return false;
  }

  static show(): void {
    if (ChatlioClient.isLoaded()) {
      window._chatlio.show();
    }
  }

  static open(): void {
    if (ChatlioClient.isLoaded()) {
      if (ChatlioClient.isShown()) window._chatlio.open();
    }
  }

  static identify(id: string, options: Object): void {
    if (ChatlioClient.isLoaded()) {
      window._chatlio.identify(id, options);
    }
  }

  static teardown(): void {
    if (ChatlioClient.isLoaded()) {
      window._chatlio.hide();
    }
  }
}

export default ChatlioClient;
