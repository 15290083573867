// @flow
import * as React from 'react';
import DopplerImportButton from '@dopplerhq/universal-import-react';
import type { Node } from 'react';
import {
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiButton,
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
} from '@elastic/eui';
import * as Base from '../Base';
import * as hooks from '../../hooks';
import { colors, sizes } from '../../styles';
import type { TPanelKeysProps } from './types';

function PanelKeys(props: TPanelKeysProps): Node {
  const user = hooks.useUser();
  const team = user.getActiveTeam();
  const developmentKey = team.getDevelopmentKey();
  const sandboxKey = team.getSandboxKey();
  const productionKey = team.getProductionKey();

  const keys = [
    {
      title: 'Development',
      titleBadge: { text: 'FREE', color: 'hollow' },
      subTitle: 'Test the Method API in a development environment.',
      subContent: 'Start building your Method integration with unlimited access to our API.',
      badges: [
        { text: 'Simulated payments', color: '#00ECAC' },
      ],
      content: (
        <Base.Container flex centered>
          <Base.CopyText fullWidth sensitive value={developmentKey} />
          <Base.Container ml={sizes.s}>
            <DopplerImportButton
              secretName="METHOD_DEV_API_KEY"
              secretValue={developmentKey}
              height={38} />
          </Base.Container>
        </Base.Container>
      ),
    },
    {
      title: 'Sandbox',
      titleBadge: { text: 'FREE', color: 'hollow' },
      subTitle: 'Build with free limited live payments.',
      // eslint-disable-next-line max-len
      subContent: 'Test your Method integration with real payments and accounts. Send up to 20 payments ($1 limit) to real accounts per month, for free.',
      badges: [
        { text: 'Live payments', color: '#00ECAC' },
      ],
      content: sandboxKey
        ? (
          <Base.Container flex centered>
            <Base.CopyText fullWidth sensitive value={sandboxKey} />
            <Base.Container ml={sizes.s}>
              <DopplerImportButton
                secretName="METHOD_SANDBOX_API_KEY"
                secretValue={sandboxKey}
                height={38} />
            </Base.Container>
          </Base.Container>
        )
        : (
          <EuiButton fullWidth fill iconType="arrowRight" iconSide="right" onClick={() => user.onApplyForLiveSandbox()}>
            Enable Live Sandbox
          </EuiButton>
        ),
    },
    {
      title: 'Production',
      titleBadge: { text: 'PAID', color: 'hollow' },
      subTitle: 'Launch and scale with Method.',
      subContent: 'Go live with your Method integration with unlimited live payments; all requests are billed.',
      badges: [
        { text: 'Live payments', color: '#00ECAC' },
      ],
      content: productionKey
        ? (
          <Base.Container flex centered>
            <Base.CopyText fullWidth sensitive value={productionKey} />
            <Base.Container ml={sizes.s}>
              <DopplerImportButton
                secretName="METHOD_PRODUCTION_API_KEY"
                secretValue={productionKey}
                height={38} />
            </Base.Container>
          </Base.Container>
        )
        : (
          <EuiButton fill fullWidth iconType="arrowRight" iconSide="right" onClick={() => user.onApplyForProduction()}>
            Apply for Production
          </EuiButton>
        ),
    },
  ];

  return (
    <Base.Container>
      <Base.MessageAlert
        title="Don't share your keys"
        iconType="alert"
        text={(
          <>
            Never share your secret keys in plaintext with anyone, including Method employees.
            If a secret key is compromised, please rotate it.
            <br />
            <br />
            We strongly recommend using a secrets manager. Plain text files like dotenv lead
            to accidental costly leaks. Use <a href="https://www.doppler.com/l/partner-program" target="__blank">Doppler</a> for
            a developer friendly experience. AWS and Google Cloud have native solutions as well.
          </>
        )} />
      <EuiSpacer />

      <Base.Container flex wrap justify="flex-end" mb={sizes.s}>
        <EuiButtonEmpty
          href="https://docs.methodfi.com/docs/reference/docs/1.%20Method%20API/3-Environments.md"
          target="_blank"
          iconType="link">
          View Environment Docs
        </EuiButtonEmpty>
      </Base.Container>

      <Base.Container flex wrap justify="space-between">
        <EuiFlexGroup responsive wrap>
          {keys.map((key) => (
            <EuiFlexItem key={key.title}>
              <EuiPanel hasBorder paddingSize="m" hasShadow={false} style={{ borderWidth: 1 }}>
                <Base.Container flex="column" justify="space-between" full>
                  <Base.Container fullWidth>

                    <Base.ContainerSplit
                      left={<EuiText><h3>{key.title}</h3></EuiText>}
                      rightGrow={false}
                      right={<EuiBadge color={key.titleBadge.color}>{key.titleBadge.text}</EuiBadge>} />

                    <Base.Container flex bb={1} bbc={colors.gray} pb={sizes.m} my={sizes.m}>
                      {key.badges.map((badge) => (
                        <EuiBadge key={badge.text} color={badge.color}>
                          {badge.text}
                        </EuiBadge>
                      ))}
                    </Base.Container>

                    <Base.Container mb={sizes.l}>
                      <EuiText>
                        <h4>{key.subTitle}</h4>
                        <small>{key.subContent}</small>
                      </EuiText>
                    </Base.Container>
                  </Base.Container>
                  <Base.Container fullWidth>
                    {key.content}
                  </Base.Container>
                </Base.Container>
              </EuiPanel>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </Base.Container>
    </Base.Container>
  );
}

export default PanelKeys;
