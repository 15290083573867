// @flow
import React, { useState } from 'react';
import {
  EuiCollapsibleNav,
  EuiShowFor,
  EuiHeader,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiImage,
} from '@elastic/eui';
import type { Node } from 'react';
import type { TSidebarProps } from './types';
import * as Base from '../Base';
import { colors, sizes } from '../../styles';
import PanelTeams from './PanelTeams';
import PanelNavigation from './PanelNavigation';

function Sidebar(props: TSidebarProps): Node {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => setIsOpen(!isOpen);
  const nav = (
    <Base.Container fullHeight flex column fill={colors.dark}>
      <PanelTeams />
      <PanelNavigation />
    </Base.Container>
  );

  return [
    // Not mobile
    <EuiShowFor key="navForWide" sizes={['m', 'l', 'xl']}>
      {nav}
    </EuiShowFor>,
    // Mobile
    <EuiShowFor key="navForMobile" sizes={['xs', 's']}>
      <Base.Container pt={sizes.l * 2}>
        <EuiHeader position="fixed">
          <Base.Container flex fullWidth align="center" justify="space-between">
            <EuiHeaderSectionItemButton onClick={toggleSidebar}>
              <EuiIcon type="menu" />
            </EuiHeaderSectionItemButton>
            <EuiImage
              style={{ height: 24 }}
              alt="MethodFi Logo"
              src="https://static.methodfi.com/brand/logo-horizontal-vibrantgreen-darkgreen.png" />
            <Base.Container width={40} />
          </Base.Container>
        </EuiHeader>
      </Base.Container>
      <EuiCollapsibleNav isOpen={isOpen} onClose={toggleSidebar}>
        {nav}
      </EuiCollapsibleNav>
    </EuiShowFor>,
  ];
}

export default Sidebar;
