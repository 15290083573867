// @flow
import * as React from 'react';
import type { Node } from 'react';
import { EuiText } from '@elastic/eui';
import * as Base from '../../Base';
import * as hooks from '../../../hooks';
import type { TPanelStepProps } from '../types';

function StepIntro(props: TPanelStepProps): Node {
  const user = hooks.useUser();

  return (
    <Base.Container>
      {/* Title + description */}
      <EuiText>
        <h3>Welcome to Method, {user.first_name}!</h3>
        <span>
          Getting started with Method is easy. We&apos;ll guide you through creating a payment for your end user.
        </span>
      </EuiText>
    </Base.Container>
  );
}

export default StepIntro;
