// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import { EuiButton } from '@elastic/eui';
import * as Base from '../components/Base';
import * as Layouts from '../components/Layouts';
import * as Entities from '../components/Entities';

function EntitiesPage(): Node {
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  return (
    <Layouts.Page
      withSidebar
      title="Entities"
      rightContent={(
        <Base.Container flex justify="end">
          <EuiButton fill iconType="plusInCircle" onClick={() => setIsCreateModalOpen(true)}>
            Add Entity
          </EuiButton>
        </Base.Container>
      )}>
      <Entities.CreateModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)} />
      <Entities.PanelTable />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(EntitiesPage, { name: 'EntitiesPage' });
