// @flow
import React from 'react';
import type { Node } from 'react';
import { EuiAvatar, EuiButtonEmpty } from '@elastic/eui';
import * as Base from '../Base';
import * as hooks from '../../hooks';
import type { TPanelTeamsProps } from './types';
import { colors, sizes } from '../../styles';
import Team from '../../lib/Team';

function PanelTeams(props: TPanelTeamsProps): Node {
  const user = hooks.useUser();

  const changeTeam = (team: Team) => () => {
    if (!team.isActive) {
      Team.setActiveTeamId(team.id);
      window.location.reload();
    }
  };

  // TODO: add this in later
  // <Base.Container mb={sizes.m} radius={sizes.s}>
  //   <EuiToolTip content="New Thing" position="right">
  //     <EuiButtonEmpty contentProps={{ style: { padding: 0, margin: 0 } }}>
  //       <EuiAvatar type="space" name="" iconType="plus" size="l" color={colors.black} />
  //     </EuiButtonEmpty>
  //   </EuiToolTip>
  // </Base.Container>

  return (
    <Base.Container fullHeight py={sizes.l} px={sizes.m} br={1} brc={colors.black}>
      {user.teams.map((team) => (
        <Base.Container key={team.id} mb={sizes.m} radius={sizes.s} ba={1} bac={team.isActive ? colors.white : colors.none}>
          <EuiButtonEmpty
            onClick={changeTeam(team)}
            style={{ height: 50, width: 50 }}
            contentProps={{ style: { padding: 0, margin: 0 } }}>
            <EuiAvatar type="space" name={team.name} size="l" style={{ width: 50, height: 50 }} />
          </EuiButtonEmpty>
        </Base.Container>
      ))}
    </Base.Container>
  );
}

export default PanelTeams;
