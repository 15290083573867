// @flow
import * as React from 'react';
import { EuiAccordion, EuiText } from '@elastic/eui';
import { colors, sizes } from '../../styles';
import Container from './Container';
import type { TAccordionPanelContainerProps } from './types';

function AccordionPanelContainer(props: TAccordionPanelContainerProps): Node {
  return (
    <Container bb={1} bbc={colors.gray} mb={sizes.m}>
      <EuiAccordion
        initialIsOpen
        id={Math.random().toString()}
        arrowDisplay="right"
        buttonContent={(
          <Container py={sizes.s}>
            <EuiText size="s"><h3>{props.title}</h3></EuiText>
          </Container>
        )}>

        <Container pb={sizes.l} pt={sizes.m}>
          {props.children}
        </Container>
      </EuiAccordion>
    </Container>
  );
}

export default AccordionPanelContainer;
