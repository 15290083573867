// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { TGlobalErrorBoundaryProps } from './types';
import User from '../../lib/User';

class GlobalErrorBoundary extends React.Component<TGlobalErrorBoundaryProps, void> {
  componentDidCatch(error: Error) {
    Sentry.withScope((scope) => {
      scope.setTag('REACT_APP_VERSION', process.env.REACT_APP_VERSION);
      scope.setTag('window.location', window.location.href);
      // TODO[mdelcarmen]: uncomment this later
      // User.logoutAsync();
      Sentry.captureException(error);
    });
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
