// @flow
import { useState, useEffect } from 'react';
import AtomClient from '../lib/AtomClient';
import OctoMerchant from '../lib/OctoMerchant';

export default function useMerchants(opts: any): { completed: boolean, data: Array<any> } {
  const [__, raiseError] = useState(null); // eslint-disable-line
  const [merchants, setMerchants] = useState({ completed: false });

  useEffect(() => {
    (async () => {
      setMerchants({ completed: false });
      try {
        const response = await AtomClient.getPaginatedResource({
          resource_name: 'merchants',
          resource_init: OctoMerchant.init_from_raw,
          params: opts,
        });
        setMerchants({ completed: true, ...response });
      } catch (error) {
        raiseError(() => { throw error; });
      }
    })();
  }, Object.values(opts));

  return merchants;
}
