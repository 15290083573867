// @flow

class OctoRoutingNumber {
  id: string;
  institution_name: string;
  routing_number: string;
  logo: ?string;
  office_type: string;
  change_date: string;
  address: string;
  phone: string;

  constructor(opts: any) {
    this.id = opts.id;
    this.institution_name = opts.institution_name;
    this.routing_number = opts.routing_number;
    this.logo = opts.logo;
    this.office_type = opts.office_type;
    this.change_date = opts.change_date;
    this.address = opts.address;
    this.phone = opts.phone;
  }
}

export default OctoRoutingNumber;
