// @flow
export const DatadogLoggerNames = {
  Navigation: 'Navigation',
  AtomClient: 'AtomClient',
};

export type TDatadogLoggerNames = $Keys<typeof DatadogLoggerNames>;

export type TDatadogLoggerOptions = {
  name: TDatadogLoggerNames,
};
