// @flow
import * as React from 'react';
import {
  EuiBasicTable,
  EuiDescriptionList,
  EuiText,
} from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelCorporation(props: TPanelDefaultProps): Node {
  const { entity } = props;
  if (!entity || !entity.entity.corporation) return null;

  const owners_columns = [
    { field: 'name', name: 'Name' },
    { field: 'phone', name: 'Phone' },
    { field: 'email', name: 'Email' },
    { field: 'dob', name: 'Date of Birth' },
    { field: 'address', name: 'Address' },
  ];

  return (
    <Base.AccordionPanelContainer title="Corporation Details">
      <Base.ContainerSplit
        align="flexStart"
        leftGrow
        rightGrow
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Name</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_name()}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Doing Business As (DBA)</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_corp_dba()}</h3>
                  </EuiText>
                ),
              },
            ]} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Employer Identification Number (EIN)</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_corp_ein()}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Address</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_address()}</h3>
                  </EuiText>
                ),
              },
            ]} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelCorporation;
