// @flow
import * as React from 'react';
import { EuiText, EuiDescriptionList, EuiBadge } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelProviderIds(props: TPanelDefaultProps): Node {
  const { merchant } = props;
  if (!merchant) return null;

  const { plaid, mx, finicity } = merchant.provider_ids;

  return (
    <Base.AccordionPanelContainer title="Provider IDs">
      <Base.ContainerSplit
        align="flexStart"
        equal
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Plaid</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>
                      {plaid.length > 0
                        ? plaid.map((id) => (
                          <EuiBadge key={id}><code>{id}</code></EuiBadge>
                        ))
                        : 'None'}
                    </h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>MX</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>
                      {mx.length > 0
                        ? mx.map((id) => (
                          <EuiBadge key={id}><code>{id}</code></EuiBadge>
                        ))
                        : 'None'}
                    </h3>
                  </EuiText>
                ),
              },
            ]} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Finicity</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>
                      {finicity.length > 0
                        ? finicity.map((id) => (
                          <EuiBadge key={id}><code>{id}</code></EuiBadge>
                        ))
                        : 'None'}
                    </h3>
                  </EuiText>
                ),
              },
            ]} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelProviderIds;
