// @flow
import moment from 'moment';
import Dinero from 'dinero.js';
import * as change_case from 'change-case';
import type { TOctoResourceError } from './types';
import { OctoPaymentStatuses } from '../enums/OctoPayment';

class OctoPayment {
  id: string;
  source: string;
  destination: string;
  amount: number;
  description: string;
  status: string;
  estimated_completion_date: ?string;
  source_trace_id: ?string;
  destination_trace_id: ?string;
  reversal_id: ?string;
  type: string;
  error: ?TOctoResourceError;
  metadata: ?any;
  created_at: string;
  updated_at: string;

  constructor(opts: any) {
    this.id = opts.id;
    this.source = opts.source;
    this.destination = opts.destination;
    this.amount = opts.amount;
    this.description = opts.description;
    this.status = opts.status;
    this.estimated_completion_date = opts.estimated_completion_date;
    this.source_trace_id = opts.source_trace_id;
    this.destination_trace_id = opts.destination_trace_id;
    this.reversal_id = opts.reversal_id;
    this.type = opts.type;
    this.error = opts.error;
    this.metadata = opts.metadata;
    this.created_at = opts.created_at;
    this.updated_at = opts.updated_at;
  }

  static init_from_raw(opts: any): OctoPayment {
    return new OctoPayment(opts);
  }

  get_presentable_created_at(format: string = 'MM/DD/YY, hh:mm A'): string {
    return this.created_at ? moment(this.created_at).format(format) : 'None';
  }

  get_presentable_amount(): string {
    if (this.amount || this.amount === 0) {
      return Dinero({ amount: this.amount }).toFormat();
    }
    return '';
  }

  get_presentable_status(): string {
    return this.status ? change_case.capitalCase(this.status) : 'None';
  }

  get_color_for_status(): string {
    switch (this.status) {
      case OctoPaymentStatuses.failed: return '#FABEBA';
      case OctoPaymentStatuses.pending: return 'default';
      case OctoPaymentStatuses.processing: return '#FCF7BC';
      case OctoPaymentStatuses.sent:
      case OctoPaymentStatuses.settled: return '#00ECAC';
      case OctoPaymentStatuses.reversal_required:
      case OctoPaymentStatuses.reversal_processing: return 'warning';
      case OctoPaymentStatuses.reversed: return 'danger';
      default: return 'default';
    }
  }
}

export default OctoPayment;
