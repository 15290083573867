// @flow
export const OctoEntityTypes = {
  individual: 'individual',
  c_corporation: 'c_corporation',
  s_corporation: 's_corporation',
  llc: 'llc',
  partnership: 'partnership',
  sole_proprietorship: 'sole_proprietorship',
  receive_only: 'receive_only',
};

export const OctoEntityStatuses = {
  active: 'active',
  disabled: 'disabled',
  incomplete: 'incomplete',
};
