// @flow
import * as React from 'react';
import { EuiDescriptionList, EuiText } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelIndividual(props: TPanelDefaultProps): Node {
  const { entity } = props;
  if (!entity || !entity.entity.individual) return null;

  return (
    <Base.AccordionPanelContainer title="Individual Details">
      <Base.ContainerSplit
        align="flexStart"
        equal
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>First Name</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_individual_first_name()}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Last Name</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_individual_last_name()}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Phone</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_individual_phone()}</h3>
                  </EuiText>
                ),
              },
            ]} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Email</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_individual_email()}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Date of Birth</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_individual_dob()}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Address</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{entity.entity.get_presentable_address()}</h3>
                  </EuiText>
                ),
              },
            ]} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelIndividual;
