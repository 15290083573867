// @flow
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiText,
  EuiSpacer,
} from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelStepProps } from '../types';

function StepSendPayment(props: TPanelStepProps): Node {
  return (
    <Base.Container>
      {/* Title + description */}
      <EuiText>
        <h3>Create a payment for your entity</h3>
        <p>
          Execute a transfer on behalf of your entity. As an example, we&apos;ll send $50
          from your entity&apos;s source ("checking account") to their destination ("student loan").
        </p>
      </EuiText>

      <EuiSpacer />

      <Base.CodeBlock>
        {`curl https://dev.methodfi.com/payments \\
    -X POST \\
    -H "Authorization: Bearer <DEV_API_KEY>" \\
    -H "Content-Type: application/json" \\
    -d '{
      "amount": 5000,
      "source": "<SOURCE_ACCOUNT_ID>",
      "destination": "<DESTINATION_ACCOUNT_ID>",
      "description": "Simple Pmt"
    }'`}
      </Base.CodeBlock>
    </Base.Container>
  );
}

export default StepSendPayment;
