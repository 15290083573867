// @flow
import Cookies from 'js-cookie';
import type {
  TTeamEnvConfig,
  TTeamEnvs,
  TTeamOptions,
  TTeamRawOptions,
} from './types';
import { CookieKeys } from '../enums';
import { TeamEnvs } from '../enums/Team';

class Team {
  id: string;
  name: string;
  development_config: TTeamEnvConfig;
  sandbox_config: ?TTeamEnvConfig;
  production_config: ?TTeamEnvConfig;
  features: any;

  constructor(options: TTeamOptions) {
    this.id = options.id;
    this.name = options.name;
    this.development_config = options.development_config;
    this.sandbox_config = options.sandbox_config;
    this.production_config = options.production_config;
    this.features = options.features;
  }

  static init(options: TTeamRawOptions): Team {
    return new Team(options);
  }

  static setActiveTeamId(team_id: string): void {
    Cookies.set(CookieKeys.UserActiveTeamId, team_id);
  }

  static getActiveTeamId(): ?string {
    return Cookies.get(CookieKeys.UserActiveTeamId) || null;
  }

  static clearActiveTeamId(): void {
    Cookies.remove(CookieKeys.UserActiveTeamId);
  }

  static setActiveEnv(env: TTeamEnvs): void {
    Cookies.set(CookieKeys.UserActiveTeamEnv, env);
    window.location.reload();
  }

  static getActiveEnv(): TTeamEnvs {
    return Cookies.get(CookieKeys.UserActiveTeamEnv) || TeamEnvs.development;
  }

  get isActive(): boolean {
    return Team.getActiveTeamId() === this.id;
  }

  getDevelopmentKey(): string {
    return this.development_config.key;
  }

  getSandboxKey(): ?string {
    return this.sandbox_config ? this.sandbox_config.key : null;
  }

  getProductionKey(): ?string {
    return this.production_config ? this.production_config.key : null;
  }

  getCurrentConfig(): TTeamEnvConfig {
    switch (Team.getActiveEnv()) {
      case TeamEnvs.sandbox: return this.sandbox_config;
      case TeamEnvs.production: return this.production_config;
      default: return this.development_config;
    }
  }
}

export default Team;
