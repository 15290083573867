// @flow
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import type { Node } from 'react';
import { EuiCallOut } from '@elastic/eui';
import * as hooks from '../../hooks';
import * as Base from '../Base';
import type { TFormCreateUser } from './types';
import { sizes } from '../../styles';
import { FormFieldTypes } from '../../enums';
import AtomClient from '../../lib/AtomClient';
import { UserPurposes, UserRoles } from '../../enums/User';

function FormCreateUser(props: TFormCreateUser): Node {
  const user = hooks.useUser();
  const history = useHistory();

  const onSubmit = async (formData) => {
    const { name, ...rest } = formData;
    await AtomClient.updateUser(rest);
    if (user.teams.length === 0) await AtomClient.createTeam({ name });
    window.location.reload();
  };

  const formSections = [
    {
      key: 'user',
      fields: [
        [
          {
            type: FormFieldTypes.Text,
            name: 'first_name',
            label: 'First name',
            placeholder: 'Kevin',
            validator: (first_name) => first_name && first_name.length > 1,
          },
          {
            type: FormFieldTypes.Text,
            name: 'last_name',
            label: 'Last name',
            placeholder: 'Doyle',
            validator: (last_name) => last_name && last_name.length > 1,
          },
        ],
        {
          type: FormFieldTypes.Text,
          name: 'phone',
          label: 'Phone',
          placeholder: '5555555555',
          validator: (phone) => /^\d{10}$/.test(phone),
        },
        // Ask for company name only if use doesn't have a team.
        user.teams.length === 0 && {
          type: FormFieldTypes.Text,
          name: 'name',
          label: 'Company',
          placeholder: 'Kevin\'s Company',
          validator: (name) => name && name.length > 1,
        },
        {
          type: FormFieldTypes.Select,
          options: [
            { value: UserPurposes.PersonalFinance, text: 'Personal Finance' },
            { value: UserPurposes.ConsumerPayments, text: 'Consumer Payments' },
            { value: UserPurposes.Lending, text: 'Lending' },
            { value: UserPurposes.BankingAndBrokerage, text: 'Banking and Brokerage' },
            { value: UserPurposes.BusinessFinancialManagement, text: 'Business financial management' },
            { value: UserPurposes.Other, text: 'Other' },
          ],
          name: 'purpose',
          label: 'What are you building?',
        },
        {
          type: FormFieldTypes.Select,
          options: [
            { value: UserRoles.Developer, text: 'Developer' },
            { value: UserRoles.CustomerSupport, text: 'Customer Support' },
            { value: UserRoles.ProductManager, text: 'Product Manager' },
            { value: UserRoles.Other, text: 'Other' },
          ],
          name: 'role',
          label: 'What role are you in?',
        },
      ].filter(Boolean),
    },
  ];

  useEffect(() => {
    if (user.hasCompleteInfo()) history.replace('/');
  }, []);

  return (
    <Base.Container>
      <Base.Form
        sections={formSections}
        onSubmit={onSubmit}
        submitText="Continue"
        renderOnError={() => (
          <Base.Container mb={sizes.xl}>
            <EuiCallOut title="Something went wrong." iconType="alert" color="danger">
              <p>An unexpected error occurred, please try again later.</p>
            </EuiCallOut>
          </Base.Container>
        )} />
    </Base.Container>
  );
}

export default FormCreateUser;
