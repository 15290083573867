// @flow
import * as React from 'react';
import type { Node } from 'react';
import { EuiText } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelStepProps } from '../types';

function StepApplyForProduction(props: TPanelStepProps): Node {
  return (
    <Base.Container>
      {/* Title + description */}
      <EuiText>
        <h3>Go live!</h3>
        <p>
          🎉 You have successfully executed a payment on behalf of your entity.
          Once you&apos;ve integrated Method&apos;s API into your app, you&apos;re now ready to go live with
          production access.
        </p>
      </EuiText>
    </Base.Container>
  );
}

export default StepApplyForProduction;
