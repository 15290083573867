/* eslint-disable object-curly-newline */
// @flow
import * as React from 'react';
import * as change_case from 'change-case';
import { EuiDescriptionList, EuiText } from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import * as Base from '../../Base';
import * as utils from '../../../utils';
import type { TPanelDefaultProps } from '../types';

const fields = [
  { title: 'Loan Amount', key: 'original_loan_amount', types: ['*'], formatter: utils.cents_formatter },
  { title: 'Balance', key: 'balance', types: ['*'], formatter: utils.cents_formatter },
  { title: 'Remaining Statement Balance', key: 'remaining_statement_balance', types: ['credit_card'], formatter: utils.cents_formatter },
  { title: 'Available Credit', key: 'available_credit', types: ['credit_card'], formatter: utils.cents_formatter },
  { title: 'Interest Rate', key: 'interest_rate_percentage', types: ['*'], formatter: (x) => (x ? `${x}%` : null) },
  { title: 'Interest Rate Type', key: 'interest_rate_type', types: ['*'], formatter: (x) => (x ? change_case.capitalCase(x) : null) },
  { title: 'Next Payment Amount', key: 'next_payment_minimum_amount', types: ['*'], formatter: utils.cents_formatter },
  { title: 'Next Payment Due Date', key: 'next_payment_due_date', types: ['*'], formatter: utils.date_formatter },
  { title: 'Last Payment Amount', key: 'last_payment_amount', types: ['*'], formatter: utils.cents_formatter },
  { title: 'Last Statement Balance', key: 'last_statement_balance', types: ['credit_card'], formatter: utils.cents_formatter },
  { title: 'Last Payment Date', key: 'last_payment_date', types: ['*'], formatter: utils.date_formatter },
  { title: 'Next Statement Date', key: 'next_statement_date', types: ['credit_card'], formatter: utils.date_formatter },
  { title: 'Payoff Amount', key: 'payoff_amount', types: ['mortgage', 'auto_loan'], formatter: utils.cents_formatter },
  { title: 'Payoff Amount Term (Days)', key: 'payoff_amount_term', types: ['mortgage', 'auto_loan'], formatter: (x) => x },
  { title: 'Expected Payoff Date', key: 'expected_payoff_date', types: ['*'], formatter: utils.date_formatter },
  { title: 'Opened At', key: 'opened_at', types: ['*'], formatter: utils.date_formatter },
  { title: 'Disbursed At', key: 'disbursed_at', types: ['student_loan'], formatter: utils.date_formatter },
  { title: 'Term (Months)', key: 'term_length', types: ['mortgage', 'auto_loan', 'personal_loan'] },
  { title: 'Escrow Balance', key: 'escrow_balance', types: ['mortgage'], formatter: utils.cents_formatter },
  { title: 'Property Address', key: 'address_street', types: ['mortgage'], formatter: (x) => x },
  { title: 'Property City', key: 'address_city', types: ['mortgage'], formatter: (x) => x },
  { title: 'Property State', key: 'address_state', types: ['mortgage'], formatter: (x) => x },
  { title: 'Property Zip', key: 'address_zip', types: ['mortgage'], formatter: (x) => x },
  { title: 'YTD Principal Paid', key: 'year_to_date_principal_paid', types: ['mortgage'], formatter: utils.cents_formatter },
  { title: 'YTD Interest Paid', key: 'year_to_date_interest_paid', types: ['mortgage'], formatter: utils.cents_formatter },
];

function PanelLiability(props: TPanelDefaultProps): Node {
  const history = useHistory();
  const { account } = props;
  if (!account || !account.account.liability) return null;

  const liability_type = account.account.liability.type;
  const liability_items = fields.reduce((acc, field) => {
    if (!field.types.includes(liability_type) && !field.types.includes('*')) return acc;

    const liability_data = account.account.get_liability_data();
    const value = liability_data && liability_data[field.key];

    if ([undefined, null].includes(value)) return acc;

    acc.push({ title: field.title, value: field.formatter ? field.formatter(value) : value });

    return acc;
  }, []);

  const left_items = [
    { title: 'Type', value: account.account.get_presentable_liability_type() },
    { title: 'Account Mask', value: account.account.get_presentable_mask() },
  ].concat(liability_items.slice(0, Math.floor(liability_items.length / 2)));
  const right_items = [
    {
      title: 'Merchant',
      value: account.merchant.parent_name,
      onClick: () => history.push(`/merchants/${account.merchant.mch_id}`),
    },
  ].concat(liability_items.slice(Math.floor(liability_items.length / 2)));

  return (
    <Base.AccordionPanelContainer title="Liability Details">
      <Base.ContainerSplit
        align="flexStart"
        equal
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={left_items.map((item) => ({
              title: <EuiText size="xs"><span>{item.title}</span></EuiText>,
              description: <EuiText size="xs"><h3>{item.value || 'None'}</h3></EuiText>,
            }))} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={right_items.map((item) => ({
              title: <EuiText size="xs"><span>{item.title}</span></EuiText>,
              description: (
                <Base.Container onClick={item.onClick}>
                  <EuiText size="xs"><h3>{item.value || 'None'}</h3></EuiText>
                </Base.Container>
              ),
            }))} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelLiability;
