/* eslint-disable react/no-children-prop */
// @flow
import * as React from 'react';
import { EuiCard, EuiIcon } from '@elastic/eui';
import type { Node } from 'react';
import type { TCardProps } from './types';

function Card(props: TCardProps): Node {
  const {
    onClick,
    icon,
    title,
    description,
    iconColor,
    children,
  } = props;

  return (
    <EuiCard
      hasBorder
      size="s"
      layout="horizontal"
      icon={icon && <EuiIcon size="xl" color={iconColor} type={icon} />}
      titleSize="s"
      title={title}
      description={description}
      onClick={onClick}
      children={children} />
  );
}

export default Card;
