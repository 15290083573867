// @flow
import type {
  TOctoMerchantProviderIDs,
} from './types';

class OctoMerchant {
  mch_id: string;
  parent_name: string;
  name: string;
  logo: ?string;
  description: ?string;
  note: ?string;
  types: Array<string>;
  account_prefixes: Array<string>;
  provider_ids: TOctoMerchantProviderIDs;
  customized_auth: boolean;

  constructor(opts: any) {
    this.mch_id = opts.mch_id;
    this.parent_name = opts.parent_name;
    this.name = opts.name;
    this.logo = opts.logo;
    this.description = opts.description;
    this.note = opts.note;
    this.types = opts.types;
    this.account_prefixes = opts.account_prefixes;
    this.provider_ids = opts.provider_ids;
    this.customized_auth = opts.customized_auth;
  }

  static init_from_raw(raw_opts: any): OctoMerchant {
    return new OctoMerchant(raw_opts);
  }
}

export default OctoMerchant;
