// @flow
import * as React from 'react';
import { EuiText, EuiDescriptionList } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelTraceIds(props: TPanelDefaultProps): Node {
  const { payment } = props;
  if (!payment) return null;

  return (
    <Base.AccordionPanelContainer title="Trace IDs">
      <EuiDescriptionList
        type="responsiveColumn"
        listItems={[
          {
            title: <EuiText size="xs"><span>Source Trace ID</span></EuiText>,
            description: (
              <EuiText size="xs" textAlign="right">
                <code>{payment.payment.source_trace_id || 'None'}</code>
              </EuiText>
            ),
          },
          {
            title: <EuiText size="xs"><span>Destination Trace ID</span></EuiText>,
            description: (
              <EuiText size="xs" textAlign="right">
                <code>{payment.payment.destination_trace_id || 'None'}</code>
              </EuiText>
            ),
          },
        ]} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelTraceIds;
