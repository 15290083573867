// @flow
import * as React from 'react';
import {
  EuiText,
  EuiAvatar,
} from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';
import { sizes } from '../../../styles';

function PanelHeader(props: TPanelDefaultProps): Node {
  const { merchant } = props;
  if (!merchant) return null;

  return (
    <Base.Container flex align="center" mb={sizes.l}>
      <Base.Container mr={sizes.s}>
        <EuiAvatar
          name="merchant"
          imageUrl={merchant.logo}
          style={{ height: 80, width: 80 }} />
      </Base.Container>
      <EuiText size="s">
        <h2 style={{ marginBottom: 0 }}>{merchant.name}</h2>
        <span>{merchant.parent_name}</span>
      </EuiText>
    </Base.Container>
  );
}

export default PanelHeader;
