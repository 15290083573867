// @flow
import * as React from 'react';
import { EuiDescriptionList, EuiIcon, EuiText } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelOverview(props: TPanelDefaultProps): Node {
  const { payment } = props;
  if (!payment) return null;

  return (
    <Base.AccordionPanelContainer title="Overview">
      <Base.ContainerSplit
        equal
        left={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Amount</span></EuiText>,
                description: <EuiText size="xs"><h3>{payment.payment.get_presentable_amount()}</h3></EuiText>,
              },
              {
                title: <EuiText size="xs"><span>Description</span></EuiText>,
                description: <EuiText size="xs"><h3>{payment.payment.description}</h3></EuiText>,
              },
              {
                title: <EuiText size="xs"><span>Status</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>
                      <EuiIcon type="dot" color={payment.payment.get_color_for_status()} />
                      {' '}
                      {payment.payment.get_presentable_status()}
                    </h3>
                  </EuiText>
                ),
              },
            ]} />
        )}
        right={(
          <EuiDescriptionList
            textStyle="reverse"
            listItems={[
              {
                title: <EuiText size="xs"><span>Created</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{payment.payment.get_presentable_created_at('MM/DD/YYYY, hh:mm A')}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Estimated Completion Date</span></EuiText>,
                description: (
                  <EuiText size="xs">
                    <h3>{payment.payment.estimated_completion_date || 'None'}</h3>
                  </EuiText>
                ),
              },
              {
                title: <EuiText size="xs"><span>Payment ID</span></EuiText>,
                description: <EuiText size="xs"><h3>{payment.payment.id}</h3></EuiText>,
              },
            ]} />
        )} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelOverview;
