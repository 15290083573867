// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import { EuiText, EuiSpacer } from '@elastic/eui';
import * as Layouts from '../components/Layouts';
import * as Login from '../components/Login';
import * as Base from '../components/Base';
import * as colors from '../styles/colors';
import type { TLoginPageProps } from './types';

const valuePropTitle = 'By creating a free account, you can:';
const valuePropList = [
  {
    title: 'Build Today. Launch Tomorrow.',
    description: 'The easiest way to add debt repayment and bank transfers to your app.',
    iconType: 'checkInCircleFilled',
    iconColor: colors.dark,
  },
  {
    title: 'Start integrating today.',
    description: 'Use our free development API to connect your app with over 2000+ payable merchants.',
    iconType: 'checkInCircleFilled',
    iconColor: colors.dark,
  },
  {
    title: 'Focus on building your product.',
    description: 'We\'ll handle compliance requirements, identity verification, and bank partnerships so you can focus on building.',
    iconType: 'checkInCircleFilled',
    iconColor: colors.dark,
  },
];

function LoginPage(props: TLoginPageProps): Node {
  const { isNewUser } = props;

  const { title, description } = isNewUser
    ? {
      // Login
      title: 'Create your account',
      description: 'Welcome to Method! Create a free development account to get started.',
    } : {
      // Create Team
      title: 'Welcome to Method',
      description: 'Enter your email address to log in or sign up.',
    };

  return (
    <Layouts.Page>
      <Base.ContainerSplit
        equal
        gutterSize="xl"
        align="flexStart"
        left={(
          <>
            <EuiText>
              <h1>{title}</h1>
              <p>{description}</p>
            </EuiText>
            <EuiSpacer size="xl" />
            <Login.PanelLogin isNewUser={isNewUser} />
          </>
        )}
        right={(
          <Login.PanelValueProp title={valuePropTitle} data={valuePropList} />
        )} />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(LoginPage, { name: 'LoginPage' });
