// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiPanel,
  EuiButton,
  EuiSpacer,
  EuiButtonEmpty,
} from '@elastic/eui';
import type { TPanelStepContainerProps } from '../types';
import * as hooks from '../../../hooks';

function StepContainer(props: TPanelStepContainerProps): Node {
  const user = hooks.useUser();
  const {
    hasPrev,
    hasNext,
    onBack,
    onNext,
    children,
  } = props;

  return (
    <EuiPanel hasBorder paddingSize="l" hasShadow={false}>
      {/* Content */}
      {children}
      <EuiSpacer size="xl" />

      {/* Buttons */}
      {hasPrev && <EuiButtonEmpty onClick={onBack}>Back {' '}</EuiButtonEmpty>}
      {' '}
      {hasNext && (
        <EuiButton fill onClick={onNext}>
          {hasPrev ? 'Continue' : 'Get Started'}
        </EuiButton>
      )}
      {!hasNext && (
        <EuiButton fill onClick={() => user.onApplyForProduction()}>
          Apply for Production
        </EuiButton>
      )}
    </EuiPanel>
  );
}

export default StepContainer;
