// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import { useParams } from 'react-router-dom';
import * as Layouts from '../components/Layouts';
import * as hooks from '../hooks';
import * as AccountDetails from '../components/AccountDetails';
import * as Base from '../components/Base';

function AccountDetailsPage(): Node {
  const account = hooks.useAccount(useParams().accountId);
  const user = hooks.useUser();
  const currentConfig = user.getActiveTeam().getCurrentConfig();

  return (
    <Layouts.Page
      withSidebar
      title="Account Details"
      rightContent={currentConfig.features.apps_dash_should_enable_crud && (
        <AccountDetails.RightContent account={account.data} />
      )}>
      <AccountDetails.PanelHeader account={account.data} />
      <AccountDetails.PanelOverview account={account.data} />
      {account.data
        && account.data.account.error
        && <Base.AccordionPanelError {...account.data.account.error} />}
      <AccountDetails.PanelACH account={account.data} />
      <AccountDetails.PanelLiability account={account.data} />
      <AccountDetails.PanelClearing account={account.data} />
      <AccountDetails.PanelDisbursements account={account.data} />
      <AccountDetails.PanelHolder account={account.data} />
      <AccountDetails.PanelRecentPayments account={account.data} />
      <AccountDetails.PanelMetadata account={account.data} />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(AccountDetailsPage, { name: 'AccountDetailsPage' });
