// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import { useParams } from 'react-router-dom';
import * as Layouts from '../components/Layouts';
import * as hooks from '../hooks';
import * as EntityDetails from '../components/EntityDetails';
import * as Base from '../components/Base';

function EntityDetailsPage(): Node {
  const entity = hooks.useEntity(useParams().entityId);
  const user = hooks.useUser();
  const currentConfig = user.getActiveTeam().getCurrentConfig();

  return (
    <Layouts.Page
      withSidebar
      title="Entity Details"
      rightContent={currentConfig.features.apps_dash_should_enable_crud && (
        <EntityDetails.RightContent entity={entity.data} />
      )}>
      <EntityDetails.PanelHeader entity={entity.data} />
      <EntityDetails.PanelOverview entity={entity.data} />
      {entity.data
        && entity.data.entity.error
        && <Base.AccordionPanelError {...entity.data.entity.error} />}
      <EntityDetails.PanelIndividual entity={entity.data} />
      <EntityDetails.PanelCorporation entity={entity.data} />
      <EntityDetails.PanelCorporationOwners entity={entity.data} />
      <EntityDetails.PanelAccounts entity={entity.data} />
      <EntityDetails.PanelMetadata entity={entity.data} />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(EntityDetailsPage, { name: 'EntityDetailsPage' });
