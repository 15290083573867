// @flow
import OctoEntity from './OctoEntity';

class OctoPopulatedEntity {
  entity: OctoEntity

  constructor(opts: any) {
    this.entity = opts.entity;
  }

  static init_from_raw(raw_opts: any): OctoPopulatedEntity {
    return new OctoPopulatedEntity({
      entity: new OctoEntity(raw_opts.entity),
    });
  }
}

export default OctoPopulatedEntity;
