// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import * as Layouts from '../components/Layouts';
import * as Payments from '../components/Payments';

function PaymentsPage(): Node {
  return (
    <Layouts.Page withSidebar title="Payments">
      <Payments.PanelTable />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(PaymentsPage, { name: 'PaymentsPage' });
