// @flow
import * as React from 'react';
import {
  EuiText,
  EuiBasicTable,
  EuiBadge,
  EuiAvatar,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';
import { sizes } from '../../../styles';
import OctoPopulatedAccount from '../../../lib/OctoPopulatedAccount';
import * as hooks from '../../../hooks';

function PanelAccounts(props: TPanelDefaultProps): Node {
  const { entity } = props;
  if (!entity) return null;

  const history = useHistory();
  const accounts = hooks.useAccounts({ holder_id: entity.entity.id });

  const columns = [
    {
      render: (item: OctoPopulatedAccount) => item.account.get_presentable_created_at(),
      name: 'Created',
    },
    {
      render: (item: OctoPopulatedAccount) => (
        <>
          <Base.Container mr={sizes.s}>
            <EuiAvatar
              name="Bank"
              imageUrl={item.get_logo()}
              style={{ height: 25, width: 25 }} />
          </Base.Container>
          <EuiText size="xs">{item.get_presentable_name()}</EuiText>
        </>
      ),
      name: 'Name',
    },
    {
      render: (item: OctoPopulatedAccount) => item.account.get_presentable_type_with_mask(),
      name: 'Type',
    },
    {
      isExpander: true,
      render: (item: OctoPopulatedAccount) => (
        <EuiBadge color={item.account.get_color_for_status()}>
          {item.account.get_presentable_status()}
        </EuiBadge>
      ),
      name: 'Status',
    },
  ];

  return (
    <Base.AccordionPanelContainer title="Accounts">
      <EuiBasicTable
        compressed
        tableLayout="auto"
        loading={!accounts.completed}
        style={{ backgroundColor: 'transparent' }}
        items={accounts.completed ? accounts.data : []}
        columns={columns}
        rowProps={(item: OctoPopulatedAccount) => ({
          onClick: () => history.push(`/accounts/${item.account.id}`),
        })} />
    </Base.AccordionPanelContainer>
  );
}

export default PanelAccounts;
