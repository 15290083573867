// @flow
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import type { Node } from 'react';

function NotFoundPage(): Node {
  const history = useHistory();

  useEffect(() => {
    history.replace('/');
  }, []);

  return (
    <h1>404</h1>
  );
}

export default Sentry.withProfiler(NotFoundPage, { name: 'NotFoundPage' });
