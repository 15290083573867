// @flow
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FIRClient from '../lib/FIRClient';
import User from '../lib/User';
import AtomClient from '../lib/AtomClient';

export default function useProvideAuth(): { completed: boolean, user: ?User } {
  const [__, raiseError] = useState(null); // eslint-disable-line
  const [auth, setAuth] = useState({ completed: false, user: null });
  const history = useHistory();

  const determineRedirection = (user: ?User) => {
    setAuth({ completed: true, user });

    const authenticatedPath = history.location.pathname === '/login' ? '/' : history.location.pathname;
    if (user) history.replace(user.hasCompleteInfo() ? authenticatedPath : '/login/create_team');
    else history.replace('/login');
  };

  useEffect(() => {
    (async () => {
      try {
        let user: ?User;

        // Login user;
        if (FIRClient.isEmailAuthLink(window.location.href)) {
          const firUser = await FIRClient.signInWithEmailLinkAsync(window.location.href);
          if (firUser) await AtomClient.loginWithFIRToken(firUser.token);
        }

        if (AtomClient.hasAuthToken()) {
          const [_user, teams] = await Promise.all([
            AtomClient.getUser(),
            AtomClient.getTeams(),
          ]);

          _user.teams = teams;
          user = User.init(_user);
        }

        // Fetch team;
        if (user) user = await user.loadTeamAsync();

        // Redirect;
        determineRedirection(user);
      } catch (error) {
        raiseError(() => { throw error; });
      }
    })();
  }, [window.location.href]);

  return auth;
}
