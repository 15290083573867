// @flow
import React from 'react';
import type { Node } from 'react';
import type { TContainerProps } from './types';
import { colors } from '../../styles';

function Container(props: TContainerProps): Node {
  const {
    // margin
    mx, my, mt, mb, ml, mr, ma,
    // padding
    px, py, pt, pb, pl, pr, pa,
    // display
    flex, wrap, display, centered, align, justify,
    // background
    fill,
    // position
    zIndex, absolute, relative, top, bottom, left, right,
    // sizing
    full, height, width, fullHeight, fullWidth, minHeight, minWidth,
    // border
    radius,
    bx, by, bt, bb, bl, br, ba,
    bxc, byc, btc, bbc, blc, brc, bac,
    children,
    onClick,
    scroll,
  } = props;

  const margin = {
    marginTop: mt || my || ma || 0,
    marginBottom: mb || my || ma || 0,
    marginLeft: ml || mx || ma || 0,
    marginRight: mr || mx || ma || 0,
  };

  const padding = {
    paddingTop: pt || py || pa || 0,
    paddingBottom: pb || py || pa || 0,
    paddingLeft: pl || px || pa || 0,
    paddingRight: pr || px || pa || 0,
  };

  const _display = {
    display: flex ? 'flex' : (display || 'block'),
    justifyContent: centered ? 'center' : (justify || 'flex-start'),
    alignItems: centered ? 'center' : (align || 'start'),
    flexDirection: flex || 'row',
    flexWrap: (flex && wrap) ? 'wrap' : 'nowrap',
  };

  const background = {
    backgroundColor: fill || 'transparent',
  };

  const position = {
    zIndex: zIndex || undefined,
    position: absolute ? 'absolute' : (relative ? 'relative' : undefined),
    top: top || undefined,
    bottom: bottom || undefined,
    left: left || undefined,
    right: right || undefined,
  };

  const sizing = {
    height: (full || fullHeight) ? '100%' : (height || 'auto'),
    width: (full || fullWidth) ? '100%' : (width || 'auto'),
    minHeight: (full || fullHeight) ? '100%' : (minHeight || 'auto'),
    minWidth: (full || fullWidth) ? '100%' : (minWidth || 'auto'),
  };

  const border = {
    borderRadius: radius || 0,
    borderStyle: (bx || by || bt || bb || bl || br || ba) ? 'solid' : undefined,

    borderTopWidth: bt || by || ba || 0,
    borderBottomWidth: bb || by || ba || 0,
    borderLeftWidth: bl || bx || ba || 0,
    borderRightWidth: br || bx || ba || 0,

    borderTopColor: btc || byc || bac || colors.dark,
    borderBottomColor: bbc || byc || bac || colors.dark,
    borderLeftColor: blc || bxc || bac || colors.dark,
    borderRightColor: brc || bxc || bac || colors.dark,
  };

  const cursor = {
    cursor: onClick ? 'pointer' : undefined,
  };

  const overflow = {
    overflow: scroll ? 'scroll' : undefined,
  };

  const classNames = [];
  if (onClick) classNames.push('method-ui-container-onclick');

  return (
    <div
      className={classNames.join(' ')}
      onClick={onClick || undefined}
      style={({
        ...margin,
        ...padding,
        ..._display,
        ...background,
        ...position,
        ...sizing,
        ...border,
        ...cursor,
        ...overflow,
      })}>
      {children}
    </div>
  );
}

export default Container;
