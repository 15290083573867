// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiPanel,
  EuiIcon,
  EuiSpacer,
  EuiHorizontalRule,
  EuiText,
  EuiDescriptionList,
  EuiDescriptionListTitle,
  EuiDescriptionListDescription,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';
import type { TPanelValuePropProps } from './types';

function PanelValueProp(props: TPanelValuePropProps): Node {
  const { title, data } = props;

  return (
    <EuiPanel paddingSize="none" color="subdued" borderRadius="none">
      <EuiText>
        <h3>{title}</h3>
      </EuiText>
      <EuiHorizontalRule margin="m" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
      <EuiDescriptionList compressed>
        {data.map((valueProp) => [
          <EuiFlexGroup responsive={false} key={valueProp.title} gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiIcon type={valueProp.iconType} style={{ marginTop: 4, color: valueProp.iconColor }} />
            </EuiFlexItem>
            <EuiFlexItem grow>
              <EuiDescriptionListTitle>
                {valueProp.title}
              </EuiDescriptionListTitle>
              <EuiSpacer size="s" />
              <EuiDescriptionListDescription>
                {valueProp.description}
              </EuiDescriptionListDescription>
            </EuiFlexItem>
          </EuiFlexGroup>,
          <EuiSpacer key={`spacer-${valueProp.title}`} size="m" />,
        ])}
      </EuiDescriptionList>
    </EuiPanel>
  );
}

export default PanelValueProp;
