// @flow
import * as React from 'react';
import type { Node } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Pages
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import PaymentsPage from './pages/PaymentsPage';
import PaymentDetailsPage from './pages/PaymentDetailsPage';
import AccountsPage from './pages/AccountsPage';
import AccountDetailsPage from './pages/AccountDetailsPage';
import EntitiesPage from './pages/EntitiesPage';
import EntityDetailsPage from './pages/EntityDetailsPage';
import MerchantsPage from './pages/MerchantsPage';
import MerchantDetailsPage from './pages/MerchantDetailsPage';
// import WebhooksPage from './pages/WebhooksPage';
// import WebhookDetailsPage from './pages/WebhookDetailsPage';
import TeamKeysPage from './pages/TeamKeysPage';
import TeamSettingsPage from './pages/TeamSettingsPage';
import NotFoundPage from './pages/NotFoundPage';
import * as Providers from './components/Providers';
import * as Navigation from './components/Navigation';

import FIRClient from './lib/FIRClient';
import GlobalErrorBoundary from './components/Base/GlobalErrorBoundary';
import { Pages } from './pages/types';

FIRClient.init();

// TODO[mdelcarmen]: use MemoryRouter and
//  persist filters in URL query params
function App(): Node {
  return (
    <GlobalErrorBoundary>
      <BrowserRouter>
        <Providers.Auth>
          <Switch>
            {/* Auth */}
            <Navigation.Route exact path={Pages.Login.path} component={LoginPage} />
            <Navigation.Route isProtected path={Pages.LoginCreateTeam.path} component={() => <LoginPage isNewUser />} />
            {/* Dashboard */}
            <Navigation.Route isProtected exact path={Pages.Dashboard.path} component={DashboardPage} />
            {/* Payments */}
            <Navigation.Route isProtected exact path={Pages.Payments.path} component={PaymentsPage} />
            <Navigation.Route isProtected exact path={Pages.PaymentDetails.path} component={PaymentDetailsPage} />
            {/* Accounts */}
            <Navigation.Route isProtected exact path={Pages.Accounts.path} component={AccountsPage} />
            <Navigation.Route isProtected exact path={Pages.AccountDetails.path} component={AccountDetailsPage} />
            {/* Entities */}
            <Navigation.Route isProtected exact path={Pages.Entities.path} component={EntitiesPage} />
            <Navigation.Route isProtected exact path={Pages.EntityDetails.path} component={EntityDetailsPage} />
            {/* Merchants */}
            <Navigation.Route isProtected exact path={Pages.Merchants.path} component={MerchantsPage} />
            <Navigation.Route isProtected exact path={Pages.MerchantDetails.path} component={MerchantDetailsPage} />
            {/* Teams */}
            <Navigation.Route isProtected exact path={Pages.Keys.path} component={TeamKeysPage} />
            <Navigation.Route isProtected exact path={Pages.Settings.path} component={TeamSettingsPage} />
            {/* 404 */}
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Providers.Auth>
      </BrowserRouter>
    </GlobalErrorBoundary>
  );
}

// TODO[mdelcarmen]: webhooks
// {/* Webhooks */}
// <Navigation.Route isProtected exact path={Pages.Webhooks.path} component={WebhooksPage} />
// <Navigation.Route isProtected exact path={Pages.WebhookDetails.path} component={WebhookDetailsPage} />

export default App;
