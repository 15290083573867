// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiButtonGroup,
  EuiCallOut,
  EuiIcon,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import * as Base from '../../Base';
import type { TCreateModalProps } from '../types';
import { sizes } from '../../../styles';
import { FormFieldTypes } from '../../../enums';
import { OctoEntityTypes } from '../../../enums/OctoEntity';
import * as validators from '../../../validators';
import usStates from '../../../utils/usStates';
import * as hooks from '../../../hooks';
import AtomClient from '../../../lib/AtomClient';
import OctoEntity from '../../../lib/OctoEntity';

function CreateModal(props: TCreateModalProps): Node {
  const {
    isOpen,
    onClose,
  } = props;
  const history = useHistory();
  const user = hooks.useUser();
  const currentConfig = user.getActiveTeam().getCurrentConfig();

  const [entityType, setEntityType] = React.useState(OctoEntityTypes.individual);

  const formSections = [
    {
      key: 'individual_info',
      title: 'Information',
      fields: [
        [
          {
            type: FormFieldTypes.Text,
            name: 'first_name',
            label: 'First name',
            placeholder: 'Kevin',
            validator: (first_name) => first_name && first_name.length > 1,
          },
          {
            type: FormFieldTypes.Text,
            name: 'last_name',
            label: 'Last name',
            placeholder: 'Doyle',
            validator: (last_name) => last_name && last_name.length > 1,
          },
        ],
        {
          type: FormFieldTypes.Text,
          name: 'phone',
          label: 'Phone',
          placeholder: '5555555555',
          validator: (phone) => /^\d{10}$/.test(phone),
          fullWidth: true,
        },
        {
          type: FormFieldTypes.Text,
          name: 'email',
          label: 'Email',
          placeholder: 'team@methodfi.com',
          validator: (email) => !validators.base.email.validate(email).error,
          fullWidth: true,
          optional: true,
        },
        {
          type: FormFieldTypes.Text,
          name: 'dob',
          label: 'Date of birth',
          placeholder: 'YYYY-MM-DD',
          validator: (value) => /^\d{4}-\d{2}-\d{2}$/.test(value),
          fullWidth: true,
          optional: true,
        },
        currentConfig.features.entities_individual_allow_assumed_kyc_with_full_bio && {
          type: FormFieldTypes.Text,
          name: 'ssn',
          label: 'Social security number',
          placeholder: '123456789',
          validator: (value) => /^\d{9}$/.test(value),
          fullWidth: true,
          maxLength: 9,
          optional: true,
        },
      ].filter(Boolean),
    },
    {
      key: 'individual_address',
      title: 'Address',
      fields: [
        {
          label: 'Street',
          name: 'street',
          type: FormFieldTypes.Text,
          placeholder: '3300 N Interstate 35',
          validator: (value) => Boolean(value),
          fullWidth: true,
          optional: true,
        },
        {
          label: 'City',
          name: 'city',
          type: FormFieldTypes.Text,
          placeholder: 'Austin',
          validator: (value) => Boolean(value),
          fullWidth: true,
          optional: true,
        },
        [
          {
            label: 'State',
            name: 'state',
            type: FormFieldTypes.Select,
            value: null,
            options: [{ text: 'Select state', value: null }, ...usStates],
            optional: true,
          },
          {
            label: 'Zip code',
            name: 'zip',
            type: FormFieldTypes.Text,
            placeholder: '78705',
            validator: (value) => Boolean(value) && /^\d{5}$/.test(value),
            maxLength: 5,
            optional: true,
          },
        ],
      ],
    },
  ];

  const onSubmit = async (formData) => {
    const createIndividualPayload = {
      type: 'individual',
      individual: {},
    };
    if (formData.first_name) createIndividualPayload.individual.first_name = formData.first_name;
    if (formData.last_name) createIndividualPayload.individual.last_name = formData.last_name;
    if (formData.phone) createIndividualPayload.individual.phone = formData.phone;
    if (formData.dob) createIndividualPayload.individual.dob = formData.dob;
    if (formData.email) createIndividualPayload.individual.email = formData.email;
    if (formData.ssn) createIndividualPayload.individual.ssn = formData.ssn;

    const hasAddress = Boolean(formData.street || formData.city || formData.state || formData.zip);
    if (hasAddress) {
      createIndividualPayload.address = {
        line1: formData.street || null,
        city: formData.city || null,
        state: formData.state || null,
        zip: formData.zip || null,
      };
    }

    const entity = await AtomClient.createResource<OctoEntity>({
      resource_name: 'entities',
      resource_init: OctoEntity.init_from_raw,
      data: createIndividualPayload,
    });

    history.push(`/entities/${entity.id}`);
  };

  return (
    <Base.Modal
      title="New entity"
      isOpen={isOpen}
      onClose={onClose}>

      <EuiButtonGroup
        isFullWidth
        legend="entity_type_options"
        color="primary"
        idSelected={entityType}
        onChange={setEntityType}
        options={[
          {
            id: OctoEntityTypes.individual,
            label: (
              <Base.Container flex centered>
                <Base.Container mr={sizes.s} flex centered>
                  <EuiIcon type="user" />
                </Base.Container>
                <EuiText size="s">
                  Person
                </EuiText>
              </Base.Container>
            ),
          },
          {
            id: OctoEntityTypes.c_corporation,
            isDisabled: true,
            label: (
              <Base.Container flex centered>
                <Base.Container mr={sizes.s} flex centered>
                  <EuiIcon type="users" />
                </Base.Container>
                <EuiText size="s">
                  Business
                </EuiText>
              </Base.Container>
            ),
          },
        ]} />

      <EuiSpacer />

      <Base.Form
        sections={formSections}
        onSubmit={onSubmit}
        submitText="Create"
        renderOnError={() => (
          <Base.Container mb={sizes.m}>
            <EuiCallOut title="Invalid entity information." iconType="alert" color="danger">
              <p>Please verify the entity&apos;s information and try again.</p>
            </EuiCallOut>
          </Base.Container>
        )} />
    </Base.Modal>
  );
}

export default CreateModal;
