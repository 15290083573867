// @flow
import * as React from 'react';
import { EuiAvatar, EuiText } from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';
import { sizes } from '../../../styles';

function PanelHolder(props: TPanelDefaultProps): Node {
  const history = useHistory();
  const { account } = props;
  if (!account) return null;

  return (
    <Base.AccordionPanelContainer title="Account Holder">
      <Base.Container flex align="center" onClick={() => history.push(`/entities/${account.holder.id}`)}>
        <Base.Container mr={sizes.s}>
          <EuiAvatar
            name="destination-entity"
            imageUrl={account.holder.get_logo()}
            style={{ height: 40, width: 40 }} />
        </Base.Container>
        <EuiText size="xs">
          <h3 style={{ marginBottom: 0 }}>{account.holder.get_presentable_name()}</h3>
          <span>{account.holder.get_presentable_type()}</span>
        </EuiText>
      </Base.Container>
    </Base.AccordionPanelContainer>
  );
}

export default PanelHolder;
