// @flow
import React from 'react';
import type { Node } from 'react';
import {
  EuiCallOut,
  EuiPanel,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';
import type { TMessageAlertProps } from './types';
import { colors } from '../../styles';

function MessageAlert(props: TMessageAlertProps): Node {
  const {
    title,
    iconType,
    text,
    color = colors.dark,
    fill = 'transparent',
  } = props;

  return (
    <EuiPanel hasBorder hasShadow={false} paddingSize="none" style={{ borderWidth: 1 }}>
      <EuiCallOut
        style={{ color, backgroundColor: fill, borderRadius: 4 }}
        title={(
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiIcon type={iconType} style={{ color }} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <span style={{ color }}>{title}</span>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}>
        <p>{text}</p>
      </EuiCallOut>
    </EuiPanel>
  );
}

export default MessageAlert;
