export const TeamContactTypes = {
  Billing: 'Billing',
  Admin: 'Admin',
  Technical: 'Technical',
};

export const TeamEnvs = {
  production: 'production',
  sandbox: 'sandbox',
  development: 'development',
};
