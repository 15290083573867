// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import * as Layouts from '../components/Layouts';
import * as TeamKeys from '../components/TeamKeys';

function TeamKeysPage(): Node {
  return (
    <Layouts.Page withSidebar title="API Keys">
      <TeamKeys.PanelKeys />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(TeamKeysPage, { name: 'TeamKeysPage' });
