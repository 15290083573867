/* eslint-disable object-curly-newline */
// @flow
import * as React from 'react';
import * as change_case from 'change-case';
import { EuiBasicTable } from '@elastic/eui';
import * as Base from '../../Base';
import * as utils from '../../../utils';
import type { TPanelDefaultProps } from '../types';

function PanelDisbursements(props: TPanelDefaultProps): Node {
  const { account } = props;

  if (!account || account?.account?.liability?.type !== 'student_loans') return null;

  const student_loans = account.account?.liability?.student_loans?.disbursements;

  if (!student_loans || !student_loans.length) return null;

  const columns = [
    {
      render: (item) => item.sequence,
      name: 'Sequence',
      width: '100%',
    },
    {
      render: (item) => utils.cents_formatter(item.original_loan_amount),
      name: 'Loan Amount',
      width: '100%',
    },
    {
      render: (item) => utils.cents_formatter(item.balance),
      name: 'Balance',
      width: '100%',
    },
    {
      render: (item) => (item.interest_rate_percentage ? `${item.interest_rate_percentage}%` : null),
      name: 'Interest Rate',
      width: '100%',
    },
    {
      render: (item) => (item.interest_rate_type ? change_case.capitalCase(item.interest_rate_type) : null),
      name: 'Interest Rate Type',
      width: '100%',
    },
    {
      render: (item) => utils.date_formatter(item.next_payment_due_date),
      name: 'Next Payment Due Date',
      width: '100%',
    },
    {
      render: (item) => utils.date_formatter(item.expected_payoff_date),
      name: 'Expected Payoff Date',
      width: '100%',
    },
    {
      render: (item) => utils.date_formatter(item.disbursed_at),
      name: 'Disbursed At',
      width: '100%',
    },
    {
      render: (item) => item.term_length,
      name: 'Term (Months)',
      width: '100%',
    },
  ];

  return (
    <Base.AccordionPanelContainer title="Disbursements">
      <EuiBasicTable
        compressed
        style={{ backgroundColor: 'transparent' }}
        items={student_loans || []}
        columns={columns}
     />
    </Base.AccordionPanelContainer>
  );
}

export default PanelDisbursements;
