// @flow
import * as change_case from 'change-case';
import OctoPayment from './OctoPayment';
import OctoAccount from './OctoAccount';
import OctoEntity from './OctoEntity';
import OctoRoutingNumber from './OctoRoutingNumber';
import OctoMerchant from './OctoMerchant';
import { OctoAccountTypes } from '../enums/OctoAccount';

class OctoPopulatedPayment {
  payment: OctoPayment;
  source_account: OctoAccount;
  destination_account: OctoAccount;
  source_entity: OctoEntity;
  destination_entity: OctoEntity;
  source_routing_number: OctoRoutingNumber;
  destination_routing_number: ?OctoRoutingNumber;
  destination_merchant: ?OctoMerchant;

  constructor(opts: any) {
    this.payment = opts.payment;
    this.source_account = opts.source_account;
    this.destination_account = opts.destination_account;
    this.source_entity = opts.source_entity;
    this.destination_entity = opts.destination_entity;
    this.source_routing_number = opts.source_routing_number;
    this.destination_routing_number = opts.destination_routing_number;
    this.destination_merchant = opts.destination_merchant;
  }

  static init_from_raw(raw_opts: any): OctoPopulatedPayment {
    return new OctoPopulatedPayment({
      payment: new OctoPayment(raw_opts.payment),
      source_account: new OctoAccount(raw_opts.source_account),
      destination_account: new OctoAccount(raw_opts.destination_account),
      source_entity: new OctoEntity(raw_opts.source_entity),
      destination_entity: new OctoEntity(raw_opts.destination_entity),
      source_routing_number: raw_opts.source_routing_number ? new OctoRoutingNumber(raw_opts.source_routing_number) : null,
      destination_routing_number: raw_opts.destination_routing_number ? new OctoRoutingNumber(raw_opts.destination_routing_number) : null,
      destination_merchant: raw_opts.destination_merchant ? new OctoMerchant(raw_opts.destination_merchant) : null,
    });
  }

  get_presentable_source_account_name(): string {
    let name;
    if (this.source_account.type === OctoAccountTypes.ach) {
      name = this.source_routing_number
        ? this.source_routing_number.institution_name
        : `${this.source_account.ach.type} - ${this.source_account.ach.number.substr(-4)}`;
    } else if (this.source_account.type === OctoAccountTypes.clearing) {
      name = `Clearing - ${this.source_account.clearing.number.substr(-4)}`;
    }

    return change_case.capitalCase(name || 'Disabled Account');
  }

  get_presentable_destination_account_name(): string {
    let name;

    if (this.destination_account.ach) {
      name = this.destination_routing_number
        ? this.destination_routing_number.institution_name
        : `${this.destination_account.ach.type} - ${this.destination_account.ach.number.substr(-4)}`;
    } else {
      name = this.destination_merchant
        ? this.destination_merchant.name
        : `Liability - ${this.destination_account?.liability?.mask || ''}`;
      const liability_data = this.destination_account.get_liability_data();
      if (liability_data) name = liability_data.name;
    }

    return name && change_case.capitalCase(name);
  }

  get_source_account_logo(): string {
    if (this.source_routing_number && this.source_routing_number.logo) return this.source_routing_number.logo;
    return 'https://static.methodfi.com/brand/default-fi-icon.png';
  }

  get_destination_account_logo(): string {
    if (this.destination_routing_number && this.destination_routing_number.logo) return this.destination_routing_number.logo;
    if (this.destination_merchant && this.destination_merchant.logo) return this.destination_merchant.logo;
    return 'https://static.methodfi.com/brand/default-fi-icon.png';
  }
}

export default OctoPopulatedPayment;
