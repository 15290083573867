// @flow
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import type { Node } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiSpacer,
  EuiText,
  EuiHeaderBreadcrumbs,
} from '@elastic/eui';
import type { TPageProps } from './types';

import Sidebar from '../Sidebar';
import * as Base from '../Base';
import * as hooks from '../../hooks';
import ChatlioClient from '../../lib/ChatlioClient';
import Team from '../../lib/Team';
import DatadogLogger from '../../services/datadog-logger';
import { DatadogLoggerNames } from '../../services/datadog-logger/types';
import { colors, sizes } from '../../styles';
import { Pages } from '../../pages/types';

function Page(props: TPageProps): Node {
  const {
    title,
    withSidebar,
    restrictWidth,
    children,
    rightContent,
  } = props;
  const history = useHistory();

  const dd_logger = new DatadogLogger({ name: DatadogLoggerNames.Navigation });
  history.listen((location, action) => {
    dd_logger.info('Navigation State - Changed', { location, action });
  });

  const user = hooks.useUser();

  useEffect(() => {
    if (user) {
      ChatlioClient.initWithUser(user);
      Sentry.configureScope((scope) => scope.setUser({ id: user.id, email: user.email }));
    } else {
      ChatlioClient.teardown();
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [user]);

  const renderBreadCrumbs = () => {
    const breadcrumbs = history.location.pathname
      .split('/')
      .map((_, idx, arr) => arr.slice(0, idx + 1).join('/'))
      .filter(Boolean)
      .map((path) => {
        const matchedPage = Object.values(Pages)
          .find((page) => matchPath(path, { path: page.path, exact: true }));

        if (!matchedPage || !matchedPage.crumbName) return null;
        return { text: matchedPage.crumbName, onClick: () => history.push(path) };
      })
      .filter(Boolean);

    return <EuiHeaderBreadcrumbs breadcrumbs={breadcrumbs} style={{ margin: 0 }} />;
  };

  return (
    <EuiPage paddingSize="none" style={{ height: '100vh' }}>
      {/* Sidebar */}
      {withSidebar && <Sidebar />}

      <EuiPageBody style={{ maxHeight: '100vh', overflow: 'scroll' }}>
        {withSidebar && (
          <Base.Container bt={sizes.s} btc={colors.dark} flex centered>
            <Base.Container fill={colors.dark} pb={sizes.xs} px={sizes.l}>
              <EuiText style={{ color: '#00ECAC' }} textAlign="center" size="xs">
                <small>{Team.getActiveEnv().toUpperCase()}</small>
              </EuiText>
            </Base.Container>
          </Base.Container>
        )}

        <EuiPageContent hasBorder={false} hasShadow={false} color="transparent">
          <EuiPageContentBody restrictWidth={(restrictWidth || 1100)}>
            {/* Topbar */}
            {!withSidebar && [
              <EuiHeader
                key="header"
                style={{
                  padding: 0,
                  boxShadow: 'none',
                  border: 'none',
                  backgroundColor: 'transparent',
                  width: restrictWidth,
                }}>
                <EuiHeaderSection grow={false}>
                  <EuiHeaderSectionItem>
                    <Base.MethodLogo />
                  </EuiHeaderSectionItem>
                </EuiHeaderSection>
              </EuiHeader>,
              <EuiSpacer key="spacer1" size="xxl" />,
            ]}

            {withSidebar && renderBreadCrumbs()}

            <EuiSpacer size="m" />

            {title
              ? (
                <Base.ContainerSplit
                  left={<EuiText><h1>{title}</h1></EuiText>}
                  right={rightContent
                    ? <Base.Container flex justify="end">{rightContent}</Base.Container>
                    : null} />
              )
              : null}
            <EuiSpacer size="xl" />
            {/* Content */}
            {children}
            <EuiSpacer size="xl" />
            <EuiSpacer size="xl" />
          </EuiPageContentBody>

        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}

export default Page;
