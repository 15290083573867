// @flow
import { useState, useEffect } from 'react';
import AtomClient from '../lib/AtomClient';
import OctoPopulatedEntity from '../lib/OctoPopulatedEntity';

export default function useEntities(opts: any): { completed: boolean, data: Array<any> } {
  const [__, raiseError] = useState(null); // eslint-disable-line
  const [entities, setEntities] = useState({ completed: false });

  useEffect(() => {
    (async () => {
      setEntities({ completed: false });
      try {
        const response = await AtomClient.getPaginatedResource({
          resource_name: 'entities',
          resource_init: OctoPopulatedEntity.init_from_raw,
          params: opts,
        });
        setEntities({ completed: true, ...response });
      } catch (error) {
        raiseError(() => { throw error; });
      }
    })();
  }, Object.values(opts));

  return entities;
}
