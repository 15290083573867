// @flow
import * as React from 'react';
import { EuiCodeBlock } from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';

function PanelMetadata(props: TPanelDefaultProps): Node {
  const { entity } = props;
  if (!entity) return null;

  const has_metadata = entity.entity.metadata !== null;

  if (!has_metadata) return null;

  return (
    <Base.AccordionPanelContainer title="Metadata">
      <EuiCodeBlock
        isCopyable
        language="json"
        fontSize="s">
        {JSON.stringify(entity.entity.metadata, null, 2)}
      </EuiCodeBlock>
    </Base.AccordionPanelContainer>
  );
}

export default PanelMetadata;
