// @flow
import * as React from 'react';
import {
  EuiText,
  EuiAvatar,
} from '@elastic/eui';
import * as Base from '../../Base';
import type { TPanelDefaultProps } from '../types';
import { sizes } from '../../../styles';

function PanelHeader(props: TPanelDefaultProps): Node {
  const { account } = props;
  if (!account) return null;

  return (
    <Base.Container flex align="center" mb={sizes.l}>
      <Base.Container mr={sizes.s}>
        <EuiAvatar
          name="Bank"
          imageUrl={account.get_logo()}
          style={{ height: 80, width: 80 }} />
      </Base.Container>
      <EuiText size="s">
        <h2 style={{ marginBottom: 0 }}>{account.get_presentable_name()}</h2>
        <span>{account.account.get_presentable_type_with_mask()}</span>
      </EuiText>
    </Base.Container>
  );
}

export default PanelHeader;
