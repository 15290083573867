// @flow
import React, { useState } from 'react';
import {
  EuiCopy,
  EuiFieldText,
  EuiButtonIcon,
  EuiFormRow,
} from '@elastic/eui';
import type { Node } from 'react';
import type { TCopyTextProps } from './types';

function CopyText(props: TCopyTextProps): Node {
  const [visible, setVisible] = useState(false);

  const {
    label,
    value,
    sensitive,
    fullWidth,
  } = props;

  return (
    <EuiCopy textToCopy={value} display={fullWidth ? 'block' : 'inlineBlock'}>
      {(copy) => (
        <EuiFormRow fullWidth label={label}>
          <EuiFieldText
            fullWidth
            readOnly
            type={sensitive && !visible ? 'password' : 'text'}
            value={sensitive && !visible ? 'YXNkZmFzZGZhc2RmIGFzZGYgYXNkZiBz' : value}
            append={[
              // eslint-disable-next-line max-len
              sensitive && <EuiButtonIcon key="eye" iconType={visible ? 'eyeClosed' : 'eye'} aria-label="Copy" onClick={() => setVisible(!visible)} />,
              <EuiButtonIcon key="copy" iconType="copy" aria-label="Copy" onClick={copy} />,
            ].filter(Boolean)} />
        </EuiFormRow>
      )}
    </EuiCopy>
  );
}

export default CopyText;
