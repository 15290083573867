// @flow
import * as Sentry from '@sentry/react';
import * as React from 'react';
import type { Node } from 'react';
import { useParams } from 'react-router-dom';
import * as Layouts from '../components/Layouts';
import * as hooks from '../hooks';
import * as MerchantDetails from '../components/MerchantDetails';

function MerchantDetailsPage(): Node {
  const merchant = hooks.useMerchant(useParams().mchId);

  return (
    <Layouts.Page withSidebar title="Merchant Details">
      <MerchantDetails.PanelHeader merchant={merchant.data} />
      <MerchantDetails.PanelOverview merchant={merchant.data} />
      <MerchantDetails.PanelProviderIds merchant={merchant.data} />
      <MerchantDetails.PanelAccounts merchant={merchant.data} />
    </Layouts.Page>
  );
}

export default Sentry.withProfiler(MerchantDetailsPage, { name: 'MerchantDetailsPage' });
