// @flow
import * as React from 'react';
import type { Node } from 'react';
import { EuiHorizontalRule, EuiText } from '@elastic/eui';
import * as Base from '../Base';
import * as hooks from '../../hooks';
import type { TPanelTeamProps } from './types';
import { FormFieldTypes } from '../../enums';

function PanelTeam(props: TPanelTeamProps): Node {
  const user = hooks.useUser();
  const team = user.getActiveTeam();
  const formSections = [
    {
      key: 'user',
      fields: [
        [
          {
            type: FormFieldTypes.Text,
            value: user.first_name,
            name: 'first_name',
            label: 'First name',
          },
          {
            type: FormFieldTypes.Text,
            value: user.last_name,
            name: 'last_name',
            label: 'Last name',
          },
        ],
        {
          type: FormFieldTypes.Text,
          value: user.phone,
          name: 'phone',
          label: 'Phone',
        },
        {
          type: FormFieldTypes.Text,
          value: team.name,
          name: 'company_name',
          label: 'Company',
        },
        {
          type: FormFieldTypes.Text,
          value: user.purpose,
          name: 'purpose',
          label: 'What are you building?',
        },
        {
          type: FormFieldTypes.Text,
          value: user.role,
          name: 'role',
          label: 'What role are you in?',
        },
      ],
    },
  ];

  return (
    <Base.Container>
      <EuiText color="subdued">
        <p>
          This information is private. This information is not shared with users
          of your app and and is only accessible to members of your team and Method.
        </p>
      </EuiText>
      <EuiHorizontalRule margin="m" style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />

      {/* Details */}
      <Base.Form readOnly sections={formSections} />
    </Base.Container>
  );
}

export default PanelTeam;
