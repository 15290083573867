// @flow
import * as React from 'react';
import type { Node } from 'react';
import { EuiPanel } from '@elastic/eui';
import FormEmailLink from './FormEmailLink';
import FormCreateUser from './FormCreateUser';
import type { TPanelLoginProps } from './types';

function PanelLogin(props: TPanelLoginProps): Node {
  const { isNewUser } = props;
  const FormComponent = isNewUser ? FormCreateUser : FormEmailLink;

  return (
    <EuiPanel paddingSize="none" color="subdued" borderRadius="none">
      <FormComponent />
    </EuiPanel>
  );
}

export default PanelLogin;
