// @flow
export const OctoAccountTypes = {
  ach: 'ach',
  liability: 'liability',
  clearing: 'clearing',
};

export const OctoAccountACHTypes = {
  checking: 'checking',
  savings: 'savings',
};

export const OctoAccountStatuses = {
  active: 'active',
  processing: 'processing',
  closed: 'closed',
  disabled: 'disabled',
};
