// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiButton,
  EuiContextMenu,
  EuiPopover,
} from '@elastic/eui';
import type { TRightContentProps } from '../types';
import CreatePaymentModal from '../CreatePaymentModal';

function RightContent(props: TRightContentProps): Node {
  const { account } = props;
  if (!account) return null;

  const [isOpen, setIsOpen] = React.useState(false);
  const [isCreatePaymentOpen, setIsCreatePaymentOpen] = React.useState(false);

  const canCreatePayment = account.can_receive_payments();
  const onCreatePayment = () => setIsCreatePaymentOpen(true);

  if (!canCreatePayment) return null;

  return (
    <EuiPopover
      id="thing"
      button={(
        <EuiButton
          fill
          iconType="boxesVertical"
          onClick={() => setIsOpen(!isOpen)}>
          Actions
        </EuiButton>
      )}
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
      panelPaddingSize="none"
      anchorPosition="downLeft">
      <CreatePaymentModal
        account={account}
        isOpen={isCreatePaymentOpen}
        onClose={() => setIsCreatePaymentOpen(false)} />
      <EuiContextMenu
        initialPanelId={0}
        panels={[
          {
            id: 0,
            title: 'Account actions',
            items: [
              canCreatePayment && ({
                name: 'Create payment',
                icon: 'merge',
                onClick: onCreatePayment,
              }),
            ].filter(Boolean),
          },
        ]} />
    </EuiPopover>
  );
}

export default RightContent;
