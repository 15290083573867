// @flow
import * as React from 'react';
import type { Node, Context } from 'react';
import * as hooks from '../../hooks';
import type { TAuthProps } from './types';

export const authContext: Context<any> = React.createContext();

function Auth(props: TAuthProps): Node {
  const { children } = props;
  const auth = hooks.useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export default Auth;
