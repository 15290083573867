// @flow
import * as React from 'react';
import type { Node } from 'react';
import {
  EuiCallOut,
  EuiButtonIcon,
  EuiSpacer,
} from '@elastic/eui';
import { useHistory } from 'react-router-dom';
import * as Base from '../../Base';
import type { TCreatePaymentModalProps } from '../types';
import { sizes } from '../../../styles';
import { FormFieldTypes } from '../../../enums';
import SelectACHModal from './SelectACHModal';
import OctoPopulatedAccount from '../../../lib/OctoPopulatedAccount';
import OctoPayment from '../../../lib/OctoPayment';
import AtomClient from '../../../lib/AtomClient';

function CreatePaymentModal(props: TCreatePaymentModalProps): Node {
  const {
    account,
    isOpen,
    onClose,
  } = props;
  const history = useHistory();
  const [isSelectACHModalOpen, setIsSelectACHModalOpen] = React.useState(false);
  const [selectedSource, setSelectedSource] = React.useState<OctoPopulatedAccount>(null);

  const getFormSections = () => [
    {
      key: 'ach',
      fields: [
        {
          label: `Destination (${account.account.id})`,
          name: 'destination',
          type: FormFieldTypes.Text,
          readOnly: true,
          value: account.get_presentable_name(),
        },
        {
          label: 'Description',
          name: 'description',
          type: FormFieldTypes.Text,
          minLength: 1,
          maxLength: 10,
          validator: (value) => /^\w{1,10}$/.test(value),
        },
        {
          prepend: '$',
          label: 'Amount',
          name: 'amount',
          type: FormFieldTypes.Number,
          validator: (value) => Number(value) >= 1,
        },
      ],
    },
  ];

  const onSubmit = async ({ amount, description }) => {
    const payload = {
      amount: Number(amount) * 100,
      description,
      source: selectedSource.account.id,
      destination: account.account.id,
    };

    const octo_payment = await AtomClient.createResource<OctoPayment>({
      data: payload,
      resource_name: 'payments',
      resource_init: OctoPayment.init_from_raw,
    });

    history.push(`/payments/${octo_payment.id}`);
  };

  return (
    <Base.Modal
      title="New Payment"
      isOpen={isOpen}
      onClose={onClose}>
      <SelectACHModal
        account={account}
        isOpen={isSelectACHModalOpen}
        onClose={() => setIsSelectACHModalOpen(false)}
        onSelect={(acc) => {
          setSelectedSource(acc);
          setIsSelectACHModalOpen(false);
        }} />
      <Base.FormField
        readOnly
        controlledValue
        fullWidth
        name="source"
        label={selectedSource ? `Source (${selectedSource.account.id})` : 'Source'}
        type={FormFieldTypes.Text}
        placeholder="Select an ACH account"
        append={<EuiButtonIcon iconType="arrowDown" onClick={() => setIsSelectACHModalOpen(true)} />}
        value={selectedSource ? selectedSource.get_presentable_name() : ''}
        onClick={() => setIsSelectACHModalOpen(true)}
      />
      <EuiSpacer size="m" />
      <Base.Form
        sections={getFormSections()}
        onSubmit={onSubmit}
        submitText="Submit"
        renderOnError={() => (
          <Base.Container mb={sizes.m}>
            <EuiCallOut title="Invalid payment information." iconType="alert" color="danger">
              <p>Please verify the payment&apos;s information and try again.</p>
            </EuiCallOut>
          </Base.Container>
        )} />
    </Base.Modal>
  );
}

export default CreatePaymentModal;
