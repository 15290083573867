// @flow
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import type { Node } from 'react';
import { EuiSpacer, EuiAvatar, EuiText } from '@elastic/eui';
import { colors, sizes } from '../../../styles';
import * as Base from '../../Base';
import type { TPanelTableProps } from '../types';
import { useMerchants } from '../../../hooks';
import { FormFieldTypes } from '../../../enums';

function PanelTable(props: TPanelTableProps): Node {
  const history = useHistory();
  const [filter, set_filter] = React.useState({
    name: null,
    'provider_id.plaid': null,
    'provider_id.finicity': null,
    'provider_id.mx': null,
  });

  const merchants = useMerchants(filter);
  const merchants_list = merchants.data || [];

  return (
    <Base.Container>
      <Base.Container flex align="center">
        {/* Merchant */}
        <Base.Container mr={sizes.s}>
          <Base.FormField
            name="merchant_name"
            label="Name"
            placeholder="Search by name"
            type={FormFieldTypes.Search}
            onChange={(name) => set_filter({ ...filter, name: name || null })} />
        </Base.Container>
      </Base.Container>

      <EuiSpacer />

      <Base.Container flex wrap justify="space-between">
        {merchants_list.map((merchant) => (
          <Base.Container
            flex
            justify="space-between"
            align="flexStart"
            pa={sizes.m}
            key={merchant.mch_id}
            width={`calc(50% - ${sizes.s / 2}px)`}
            height="100%"
            ba={1}
            bac={colors.gray}
            mb={sizes.s}
            onClick={() => history.push(`/merchants/${merchant.mch_id}`)}>
            <Base.Container flex align="center">
              <Base.Container mr={sizes.s} ba={1} bac={colors.gray} radius={40}>
                <EuiAvatar
                  name="Merchant Logo"
                  imageUrl={merchant.logo}
                  style={{ height: 40, width: 40 }} />
              </Base.Container>
              <Base.Container>
                <EuiText size="xs">
                  <strong>{merchant.name}</strong>
                  <br />
                  <small>{merchant.parent_name}</small>
                </EuiText>
              </Base.Container>
            </Base.Container>
          </Base.Container>
        ))}
      </Base.Container>
    </Base.Container>
  );
}

export default PanelTable;
